import React, { useEffect, useState, createRef, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from "react-csv";
import queryString from 'query-string';
// @material-ui components
import {
  IconButton,
  TableContainer, Table, TableHead, TableBody,
  TablePagination,
  TableRow, TableCell,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  Select, MenuItem,
  Dialog,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
// import { containerSizesSelector, DataGrid } from '@material-ui/data-grid';

import { makeStyles } from "@material-ui/core/styles";
import Visibility from '@material-ui/icons/Visibility';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Replay from '@material-ui/icons/Replay';
import dbsExpressLogo from '../assets/img/tds-banner.png';


// core components
import {
    Button as CreativeButton,
    Card,
    GridItem,
    EmptyView,
} from '../components';
import OrderDetailModal from './modals/OrderDetailModal';
import OrderRejectReason from './modals/OrderRejectReason';
import LoggedInContainer from './LoggedInContainer';

import {
  approveOrder,
  rejectOrder,
  shipOrder,
  manualShip,
  manualDeliver,
  reshipOrder,
} from '../actions/orderActions';
import { CURRENCY_FORMATTER, DELIVERY_FEE } from '../constants';
// @material-ui/icons
// core components

import styles from './styles';
const useStyles = makeStyles(styles);

const ONCE_GENERATED_GIANT_LIST = [];
for (let i = 0; i < 10000; i++) {
  ONCE_GENERATED_GIANT_LIST.push({
    id: Date.now(),
    firstName: Math.random().toString().slice(2, 8)
  });
}

function OrdersScreen(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const location = useLocation();
  const [ loading, setLoading ] = useState(false);
  const [ modalBody, setModalBody ] = useState(null);
  const [ tablePage, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(25);
  const [ filteredCount, setFilteredCount ] = useState(0);
  const [ detail, setDetail ] = useState(null);
  const csvRef1 = createRef();
  const csvRef2 = createRef();
  const [ csvData, setCsvData ] = useState([]);

  const [ alert, setAlert ] = useState({ type: null, message: '' });
  const [ filters, setFilters ] = useState({
    // Order filters
    status: 'any',  // "any" or one of the instamobile valid statuses (Order Pending, Order Accepted, Order Completed)
    hasRx: 'any',   // One of "any", "true", "false",
    name: '',
    id: '',
  });


  useFirestoreConnect([
    { collection: 'vendor_products' },
    { collection: 'vendor_categories' },
  ])
  const products = useSelector((state) => state.firestore.data.vendor_products);


  const [ allOrders, setAll ] = useState([]);
  const [ displayOrders, setOrders ] = useState([]);


  useEffect(() => {
    if(!("Notification" in window)) {
      console.log('this browser does not support desktop notification')
    } else {
      if (Notification.permission === 'granted') {
        console.log('we have permission')
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
          console.log(permission)
        });
      }
    }
  }, []);

  const showNotification = (type, data) => {
    let notification = null;
    let order = data;
    // console.log(order, 'orders')
    console.log('SHOW NOTIFICATION LOGS', data, type);
      if ((type === 'modified' || type === 'added') && order.status === 'Order Placed' && order.id) {
        notification = new Notification("You have a New Order" , {
          body:  `${order.id} : ${order.status}`,
          icon: `${dbsExpressLogo}`,
        });

        notification.onclick = function(event) {
          event.preventDefault();
          window.open(`http://reliance-express.glyphstudios.ph/orders?id=${order.id}`, '_blank');
        };

        setTimeout(() => {
          notification.close();
        }, 10000);
      }
      else if (type === 'modified' && order.id && order.updatedAt) {
        notification = new Notification("An order has been updated", {
          body: `${order.id} : ${order.status}`,
          icon: `${dbsExpressLogo}`,
        });

        notification.onclick = function(event) {
          event.preventDefault();
          window.open(`http://reliance-express.glyphstudios.ph/orders?id=${order.id}`, '_blank');
        };

        setTimeout(() => {
          notification.close();
        }, 10000);

      } else {
        return null;
      }
};
  
  useEffect(() => {
    const unsubscribe = firestore.collection('restaurant_orders')
      .orderBy('createdAt', 'desc')
      .onSnapshot(snapshot => {
        const snapshotSize =  snapshot.size;
        const docChangesSize = snapshot.docChanges().length;
        const newData = [];
        snapshot.forEach(doc => {
          newData.push(doc.data())
        });
        newData.sort((a, b) => {
          return a.createdAt.seconds > b.createdAt.seconds;
        })
        setAll(newData);
        // Filter using existing state values. UPDATE: No need since useEffect will rerender on changing "all".
        
        snapshot.docChanges().forEach((change) => {
          const { type, doc } = change;
          const data = doc.data();
          // console.log('doc data', data);
          // console.log(type, snapshotSize, docChangesSize)
          if (type === 'added' && snapshotSize !== docChangesSize) {
            showAlert({ type: 'success', message: 'A new order has been made'});
            showNotification(type, data);
          }
          if (type === 'modified') {
              if(data.status === 'Order Cancelled' || data.status === 'Driver Rejected') {
                showAlert({ type: 'warning', message: `An order has been updated`});
              }
              showNotification(type, data);
          }
        })
      });

    return () => {
      unsubscribe();
    }

  }, [firestore]);
  
  useEffect(() => {
    if (!!allOrders) {
      setOrders(filterAllOrdersByFilterAndPage(allOrders, filters, tablePage, pageSize));
    }
  }, [allOrders, filters, tablePage, pageSize]);

  useEffect(() => {
    if(location.search) {
      const parsed = queryString.parse(location.search);
      setFilters({
        id: parsed.id,
      })
    } else {
      console.log('no search')
    }
  }, [])

  // New param: paginate (default true)
  function filterAllOrdersByFilterAndPage(all, filters, page, size, paginate = true) {
    const filterKeys = Object.keys(filters)
    const x = all.filter(o => {
      return filterKeys.reduce((running, key) => {
        if (key === 'status') {
          if (filters.status !== 'any') {
            if (filters[key] === 'In Transit') {
              return running && (o.status === filters[key] || o.status === 'Manually Shipped');
            } else if (filters[key] === 'Order Completed') {
              return running && (o.status === filters[key] || o.status === 'Manually Delivered');
            }
            return running && o.status === filters[key];
          } else {
            return running;
          }
        } else if (key === 'hasRx') {
          if (filters.hasRx !== 'any') {
            const reduced = running && o.products.reduce((running2, product) => {
              if (filters[key] === "true") {
                return running2 && product.classification === "RX";
              } else {
                return running2 && product.classification !== "RX";
              }
            }, true);
            return reduced;
          } else {
            return running;
          }
        } else if (key === 'name' && filters.name.trim().length > 0) {
          const test = filters.name.toLowerCase();
          return running && (
            o.author?.firstName.toLowerCase().indexOf(test) > -1 ||
            o.author?.lastName .toLowerCase().indexOf(test) > -1 ||
            o.author?.email.toLowerCase().indexOf(test) > -1
          )
        } else if(key === 'id') {
          if(filters.id) {
            return running && filters.id === o.id
          } else { 
            return running; 
          }
        } else {
          return running;
        }
      }, true);
    });
    if (paginate) {
      setFilteredCount(x.length);

      if (detail) {
        setDetail(null);
        setDetail(detail);
      }
      return x.slice((page - 1) * size, page * size)
    } else {
      // Return filtered but ALL pages
      return x;
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setFilters({
      ...filters,
      [name]: event.target.value,
    });
    // TODO - Do I ALWAYS have to reset the page to 1? It seems like YES...
    setPage(1);
  }
  const showAlert = ({ type, message}) => {
    if (type === 'success') {
      setAlert({ type, message });
    } else if (type === 'warning' || type === 'danger') {
      setAlert({ type: 'warning', message });
    } else if (type === 'close') {
      setAlert({
        type: null,
        message: ''
      })
    } else {
      console.warn('Invalid alert woo');
    }
  }
  const openModal = (which, i, extra) => {
    const or = displayOrders[i];
    if (which === 'detail') {
      setDetail(i);
      setModalBody(
        // OrderDetailModal
        <OrderDetailModal
          order={displayOrders[i]}
          status={or.status}
          products={products}
          close={() => openModal('close')}
          onApprove={() => { onClickApprove(i) }}
          onReject={() => { openModal('reject', i)}}
          onShip={(or) => { onClickShip(or)}}
          manualShip={(or, comment) => { onClickManualShip(or, comment) }}
          manualDeliver={(or) => { onClickManualDeliver(or) }}
          menuOpen={!!extra?.isShip}
        />
      )
    } else if (which === 'reject') {
      setDetail(null);
      setModalBody(
        <OrderRejectReason
          rejectWithReason={rejectWithReason}
          onClose={() => openModal('close')}
          order={or}
        />
      )
    } else {
      setModalBody(null);
    }
  }
  const onChangePage = (_, newPage) => {
    setPage(newPage + 1);
  }
  const onChangeRowsPerPage = (e) => {
    setPageSize(e.target.value);
    // Current "top" index of old page / new page + 1
    const newPage = Math.floor(((tablePage - 1) * pageSize)  / e.target.value) + 1;
    setPage(newPage);
  }
  const onClickApprove = (i) => {
    setLoading(true);
    // While #839 not fixed — https://github.com/prescottprue/react-redux-firebase/issues/839
    const updatedAt = new Date();
    const or = displayOrders[i];
    dispatch(approveOrder({firestore, orderId: or.id, updatedAt})).then(() => {
      console.log('Dispatch APPROVE done', or.id);
      showAlert({ type: 'success', message: 'Order accepted.'});
      setLoading(false);

      if (detail) {
        or.status = 'Preparing Order'; // Temporary until refresh happens or user recloses the modal
        const idx = displayOrders.indexOf(or);
        openModal('detail', idx); // Didn't work?!??!?!
      }
    });
  };
  const rejectWithReason = (reason, or) => {
    setLoading(true);
    console.log('REJECT WITH REASON FUNCTION');
    const updatedAt = new Date();
      dispatch(rejectOrder({ firestore, orderId: or.id, reason, updatedAt })).then(() => {
        console.log('Reject done');
        showAlert({ type: 'warning', message: `Order rejected with reason: ${reason}.`});
        setLoading(false);
      });
  }
  const onClickShip = (or) => {
    const updatedAt = new Date();
    setLoading(true);
    dispatch(shipOrder({
      firestore,
      orderId: or.id,
      products: or.products,
      updatedAt
    })).then(() => {
      console.log('Dispatch SHIP done', or.id,);
      showAlert({ type: 'success', message: 'Order is ready to ship; awaiting driver.'});
      setLoading(false);

      if (detail) {
        or.status = 'Order Accepted'; // Temporary until refresh happens or user recloses the modal
        const idx = displayOrders.indexOf(or);
        openModal('detail', idx); // Didn't work?!??!?!
      }
    });
  }
  const onClickRetryShip = (or) => {
    const updatedAt = new Date();
    setLoading(true);
    dispatch(reshipOrder({
      firestore,
      orderId: or.id,
      updatedAt
    })).then(() => {
      showAlert({ type: 'success', message: 'Order has been re-dispatched; awaiting driver.'});
      setLoading(false);
    });
  }
  const onClickManualShip = (or, comment) => {
    const updatedAt = new Date();
    setLoading(true);
    dispatch(manualShip({
      firestore,
      orderId: or.id,
      comment,
      updatedAt
    })).then(() => {
      console.log('Manual ship DONE', or.id, comment);
      showAlert({ type: 'success', message: 'Customer has been updated of manual shipping.' });
      setLoading(false);

      // Should refresh
      or.status = 'Manually Shipped';
      const idx = displayOrders.indexOf(or);
      openModal('detail', idx);
    })
  }
  const onClickManualDeliver = (or) => {
    const updatedAt = new Date();
    setLoading(true);
    dispatch(manualDeliver({
      firestore,
      orderId: or.id,
      updatedAt
    })).then(() => {
      showAlert({ type: 'success', message: 'Customer has been updated of manual delivery.' });
      setLoading(false);

      or.status = 'Manually Delivered';
      const idx = displayOrders.indexOf(or);
      openModal('detail', idx); // Didn't work?!??!?!
    })
  }
  const orderCommission = 0.03;
  const getSalesReportData = () => {
    const condensed = [];
    const unpaginated = filterAllOrdersByFilterAndPage(allOrders, filters, 1, allOrders.length, false);
    unpaginated.filter((or) => { return or.status === 'Order Completed' || or.status === 'Order Received' }).map(or => {
      or.products.map(orderItem => {
        condensed.push({
          // Same as regular export
          "Order ID": or.id,
          "Order Date": moment(or.createdAt.seconds * 1000).format('YYYY-MM-DD HH:mm:ss'),
          "Status": or.status,
          "Customer Name": or.author.firstName + " " + or.author.lastName,
          "Customer Email": or.author.email,
          "Customer Phone": or.author.phone,
          "Address": or.address.line1 + " " + or.address.line2 + " " +
            or.address.city + " " + or.address.postalCode + " " + or.address.country,

          // Per-item fields
          "Item": orderItem.name,
          "Quantity": orderItem.quantity,
          "Item Price": orderItem.price,
          "Total Price": orderItem.quantity * orderItem.price,
          "Commission (3%)": orderItem.price * orderCommission,

          // Other fields
          "Bank Charge": "",
          "Delivery Charge": or.deliveryFee != undefined ? or.deliveryFee : DELIVERY_FEE,
          "Discounts and Promos": "",
          "Net Sales": "",
          "Mode of Payment": "",
          "Mode of Courier": or.comment,
          "Status ": or.status,
        });
        return null;
      });
      return null;
    });

    return condensed;
  }
  const getSummaryData = () => {
    const unpaginated = filterAllOrdersByFilterAndPage(allOrders, filters, 1, allOrders.length, false);
    return unpaginated.filter((or) => {
      return or.status === 'Order Received' || or.status === 'Order Completed'
    }).map(or => {
      const totalPaid = or.products.reduce((sum, pr) => sum + (pr.quantity + parseFloat(pr.price)), 0);
      console.log('Including order', or.id)
      return ({
        "Order ID": or.id,
        "Order Date": moment(or.createdAt.seconds * 1000).format('YYYY-MM-DD HH:mm:ss'),
        "Status": or.status,
        "Customer Name": or.author.firstName + " " + or.author.lastName,
        "Customer Email": or.author.email,
        "Customer Phone": or.author.phone,
        "Address": or.address.line1 + " " + or.address.line2 + " " +
                  or.address.city + " " + or.address.postalCode + " " +or.address.country,
        "Order Total": totalPaid,
        "Items": or.products.reduce((str, p) => str + `${p.quantity}x ${p.name}` , ""),
        "Delivery Charge": or.deliveryFee != undefined ? or.deliveryFee : DELIVERY_FEE,
        "Order Status": or.status,
      });
    });
  }

  // Render rows
  const renderEditingRow = function(or, i) {
    return renderViewRow(or, i);
  }
  const renderViewRow = function(or, i) {
    if (!or.products || !or.author) {
      console.log('INVALID OR', or);
      return "";
    }
    let rowClass = '';
    if (or.status === "Order Placed") {
      rowClass = classes.ordersOrdersPending
    } else if (or.status === "Order Accepted") {
      rowClass = classes.ordersDriverPending
    }
    const totalPaid = or.products.reduce((sum, pr) => sum + (pr.quantity * parseFloat(pr.price)), 0) +
      (or.deliveryFee != null ? or.deliveryFee : DELIVERY_FEE);
    let canRetry = false; 
    const retryThreshold = 300; // Seconds.
    // So if the last updated date was LESS THAN x seconds ago,
    // the DIFFERENCE between Now and the updated time should be GREATER than this value.
    if (or.status === "Order Accepted") {
      if (typeof or.updatedAt === 'string') {
        canRetry = (Date.now() - new Date(or.updatedAt)) / 1000 > retryThreshold;
      } else if (or.updatedAt?.seconds) {
        canRetry = (Date.now() / 1000) - or.updatedAt.seconds > retryThreshold;
      } else {
        console.log('NO UPDATED???', or.id, or.updatedAt);
      }
    }
    let hasRx = or.products.reduce((hasPresc, pr) => {
      return hasPresc || pr.classification === "RX";
    }, false);

    let displayedStatus = or.status;
    if (or.status === 'Order Accepted') {
      displayedStatus = 'Ready to Ship';
    } else if (or.status === 'Order Placed') {
      displayedStatus = 'Pending';
    } else if (or.comment?.length) {
      displayedStatus = displayedStatus + ' (via ' + or.comment + ')';
    }

    return (
      <TableRow key={or.id} className={rowClass}>
        <TableCell>{or.id}</TableCell>
        <TableCell>{moment(or.createdAt.seconds * 1000).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
        <TableCell>{or.author.lastName + ", " + or.author.firstName} <br/> {or.author.company} </TableCell>
        <TableCell>{or.author.phone}<br />{or.author.email}</TableCell>
        <TableCell>{or.products.reduce((sum, pr) => sum + pr.quantity, 0)}</TableCell>
        <TableCell>{hasRx ? "RX" : "OTC"}</TableCell>
        <TableCell>{or.paymentMethod ? or.paymentMethod : or.payment}</TableCell>
        <TableCell style={{ textAlign: 'right' }}>{CURRENCY_FORMATTER.format(totalPaid)}</TableCell>
        <TableCell>
          {displayedStatus}
        </TableCell>
        <TableCell width={100}>
          <Tooltip title="View complete order details.">
            <IconButton aria-label="info" onClick={() => openModal('detail', i)} className={classes.orderTableButtons}>
                <Visibility/>
              </IconButton>
          </Tooltip>
          { or.status === "Preparing Order" && 
          <Tooltip title="Click here to automatically assign a driver.">
            <IconButton aria-label="ship" onClick={() => {
              // onClickShip(or)
              openModal('detail', i, { isShip: true });
            }} className={classes.orderTableButtons}>
              <LocalShipping />
            </IconButton>
          </Tooltip>
          }
          { or.status === "Order Placed" &&
            <Tooltip title="Click to confirm this order.">
              <IconButton aria-label="approve" onClick={() => onClickApprove(i)} className={classes.orderTableButtons}>
                <Check />
              </IconButton>
            </Tooltip>
          }
          { (or.status === "Order Placed" || or.status === "Preparing Order") &&
            <Tooltip title="Click to reject this order. You must select a reason in the following dialog.">
              <IconButton aria-label="reject" onClick={() => openModal('reject', i)} className={classes.orderTableButtons}>
                <Close />
              </IconButton>
            </Tooltip>
          }
          { or.status === "Order Accepted" && canRetry && 
            <Tooltip title="Click to retry finding a driver.">
              <IconButton aria-label="retry" onClick={() => onClickRetryShip(or)} className={classes.orderTableButtons}>
                <Replay />
              </IconButton>
            </Tooltip>
          }
        </TableCell>
      </TableRow>
    );
  }
  // const dgColumns = [
  //   { field: 'id', headerName: 'Order ID' },
  //   { field: 'nonexistent', headerName: 'Customer', valueGetter: (params) => {
  //     console.log('PARAM', params)
  //     return '555' + params.getValue('firstName');
  //   }},
  //   // { field: 'createdAt.seconds', headerName: 'Order Date' },
  //   // { field: 'author.firstName', headerName: 'First Name' },
  //   // { field: 'author.lastName', headerName: 'Customer' },
  //   // { field: 'author.email' }, 
  //   // { field: 'author.phone' }, f
  //   // { field: 'products' },
  // ]

  // FINAL RENDER
  return (
    <div>
      <LoggedInContainer loading={ !displayOrders || loading }>
        <GridItem xs={12} md={8} className={classes.pageTitle} style={{ marginBottom: 0 }}>
          <Typography variant="h4">Order List</Typography>
        </GridItem>
        <GridItem xs={12} md={2} className={classes.textCenter}>
          <CSVLink
            ref={csvRef1}
            data={csvData}
            filename="order-list-summary.csv"
            className="MuiButton MuiButton-textPrimary"
          ></CSVLink>
          <CreativeButton color="primary" onClick={() => {
            const tmp = getSummaryData();
            setCsvData(tmp);
            // Tick
            console.log('CURRENT A', csvRef1.current, tmp.length);
            const theReference = csvRef1.current;
            setTimeout(() => {
              theReference.link.click();
            }, 100)
          }}>Export Order Summary</CreativeButton>
        </GridItem>

        <GridItem xs={12} md={8} style={{ paddingTop: 28 }}>
        <FormControl style={{marginRight: 16}}>
            <InputLabel>Order ID</InputLabel>
            <Input name="id" value={filters.id} onChange={handleChange} />
            <FormHelperText>Filter users by order id</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl} style={{marginRight: 16}}>
            <InputLabel>Status</InputLabel>
            <Select name="status" 
              value={filters.status}
              onChange={handleChange}
            >
              <MenuItem value={"any"}>Any</MenuItem>
              <MenuItem value={"Order Placed"}>Pending</MenuItem>
              <MenuItem value={"Order Rejected"}>Rejected</MenuItem>
              <MenuItem value={"Preparing Order"}>Preparing Order</MenuItem>
              <MenuItem value={"Order Accepted"}>Ready To Ship</MenuItem>
              <MenuItem value={"Driver Pending"}>Driver Pending</MenuItem>
              <MenuItem value={"Driver Rejected"}>Driver Rejected</MenuItem>
              <MenuItem value={"In Transit"}>In Transit</MenuItem>
              <MenuItem value={"Order Completed"}>Completed</MenuItem>
              <MenuItem value={"Order Received"}>Received</MenuItem>
            </Select>
            <FormHelperText>Filter by order status</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl} style={{marginRight: 16}}>
            <InputLabel>Prescription</InputLabel>
            <Select name="hasRx" 
              value={filters.hasRx}
              onChange={handleChange}
            >
              <MenuItem value={"any"}>Any</MenuItem>
              <MenuItem value={"true"}>Only RX</MenuItem>
              <MenuItem value={"false"}>No RX only</MenuItem>
            </Select>
            <FormHelperText>Show orders with prescriptions</FormHelperText>
          </FormControl>
          <FormControl style={{marginRight: 16}}>
            <InputLabel>Name or Email</InputLabel>
            <Input name="name" value={filters.name} onChange={handleChange} />
            <FormHelperText>Filter users by name or email.</FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem xs={12} md={2} className={classes.textCenter}>
          <CSVLink
            ref={csvRef2}
            data={csvData}
            filename="sales-summary.csv"
            className="MuiButton MuiButton-textPrimary"
          ></CSVLink>
          <CreativeButton color="primary" onClick={() => {
              const tmp = getSalesReportData();
              setCsvData(tmp);
              // Tick
              console.log('CURRENT B', csvRef2.current, tmp.length);
              const theReference = csvRef2.current;
              setTimeout(() => {
                theReference.link.click();
              }, 100);
            }}
          >Export Sales Report</CreativeButton>
        </GridItem>
        
        <GridItem xs={12} md={10}>
          <Card>
            {/* <DataGrid rows={ONCE_GENERATED_GIANT_LIST} columns={dgColumns} checkboxSelection={true} /> */}

            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Count</TableCell>
                    <TableCell>RX?</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayOrders && displayOrders.map(renderViewRow)}
                  {displayOrders.length === 0 && <EmptyView colSpan={10} style={{ padding: 24 }}/>}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={tablePage - 1}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredCount}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Card>
        </GridItem>
      </LoggedInContainer>
      {/* {alert.type != null ? <SnackbarContent
        className={classes.toast}
        message={
          <span>
              {alert.message}
          </span>
        }
        close
        color={alert.type !== null ? alert.type : undefined}
        // icon="info_outline"
      /> : null} */}
      <Snackbar open={alert.type !== null} autoHideDuration={6000} onClose={() => showAlert({ type: 'close' })}>
        <Alert onClose={() => showAlert({ type: 'close' })} severity={alert.type || 'info'}>
          {alert.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={modalBody !== null}
        maxWidth="md"
        onClose={() => openModal('close')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {modalBody}
      </Dialog>
    </div>
  )
}

export default OrdersScreen;
