import React from 'react';
import {
    Box,
    DialogTitle,
    DialogContent,
    makeStyles,
    DialogActions,
    Divider,
    Typography
} from '@material-ui/core';

import {
    GridContainer, GridItem,
    Button,
  } from '../../components';

import styles from '../styles';
import buttonStyles from '../../assets/jss/material-kit-react/components/buttonStyle';

const useStyles = makeStyles({...styles, ...buttonStyles});

const UserVerifyModal = ({approve, reject, close, user}) => {
    const classes = useStyles();

    return (
        <Box className={classes.pwdImgContainer}>
            <DialogTitle style={{textAlign: 'center'}}> PWD ID Verification </DialogTitle>
            <DialogContent>
               <GridContainer style={{ marginBottom: 4, display: 'flex', flexDirection: 'column'}}>  
               <GridItem xs={12} sm={12} md={12} style={{textAlign: 'center', marginBottom: 2}}>
                        <Typography variant="body">{`Discount Relation: ${user.discountRelation ? user.discountRelation : "Not Specified"}`}</Typography>
                    </GridItem>
                    <Divider style={{ marginBottom: 10}}/>
                    <GridItem xs={12} sm={12} md={12} style={{maxWidth: '650px', textAlign: 'center'}}>
                        {user.discountID ? 
                            <img 
                             src={user.discountID} 
                             alt="pwd-id"
                             style={{width: '100%'}}/>
                         :  <span style={{textAlign: 'center'}}> No Image </span>}
                    </GridItem>
               </GridContainer>
            </DialogContent>
            <Divider style={{ marginBottom: 1}}/>
            {user.discountVerified !== 'APPROVED' && 
            <DialogActions style={{display: 'flex', alignItems: 'center', justifyContent:'space-around', marginBottom: '16px'}}>
                <Button onClick={close} color="simple" fullWidth>Close</Button> 
                <Button onClick={reject} color="rose" fullWidth>Reject</Button> 
                <Button onClick={approve} color="success" fullWidth>Approve</Button> 
            </DialogActions>
            }
        </Box>
            
    )
}

export default UserVerifyModal;
