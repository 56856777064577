import React, { Fragment, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogTitle, DialogActions,
  DialogContent, DialogContentText,
  FormControl, FormControlLabel,
  Input, FormHelperText,
  RadioGroup, Radio,
  GridList, GridListTile,
  Typography,
  Divider,
  Tooltip,
  Select, MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Print from '@material-ui/icons/Print';
import moment from 'moment';
import {
  DELIVERY_FEE,
  CURRENCY_FORMATTER
} from '../../constants';
import {
  GridContainer, GridItem,
} from '../../components';
import { dangerColor } from '../../assets/jss/material-kit-react';
import icon from '../../assets/img/reliance_xhdpi.png';
import {
  supportOrderUpdate
} from '../../actions/orderActions'

import styles from '../styles';

const useStyles = makeStyles(styles);

function OrderDetailModal(props) {
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const firebase = useFirebase();
    const auth = useSelector(state => state.firebase.auth);
    const [ role, setRole ] = useState('');

    useEffect(() => {
      if (auth.uid) {
        firestore.collection('users').doc(auth.uid).get().then(user => {
          setRole(user.data().role);
        });
      }
    }, [ auth.uid ]);

    const classes = useStyles();
    const componentRef = useRef(null);
    const [ menuOpen, setMenuOpen ] = useState(false);
    const [ selectedCourier, setSelectedCourier ] = useState('glyph');
    const [ supportStatus, setSupportStatus ] = useState(props.order.status);
    const inputRef = useRef();

    const totalPaid = props.order.products.reduce((sum, p) => sum + p.quantity * p.price, 0) +
      (props.order.deliveryFee != null ? props.order.deliveryFee : DELIVERY_FEE);

    const onPrint = useReactToPrint({ content: () => componentRef.current });
    const rxFromProducts = props.order.products.filter(p => !!p.prescription).map(p => p.prescription);
    const rxFromExtra = props.order.prescriptions || [];
    const prescriptions = [ ...rxFromProducts, ...rxFromExtra ];

    useEffect(() => {
      setMenuOpen(props.menuOpen);
    }, [props.menuOpen]);

    useEffect(() => {
      if (selectedCourier !== 'glyph') {
          inputRef.current?.focus();
      }
    }, [ selectedCourier ]);

    useEffect(() => {
      
    }, [props.order?.status])

    function onActualMenuPress(event) {
      setMenuOpen(true);
    }

    // Print, then actual render
    const supportable = props.order.status === 'Order Accepted' || props.order.status === 'In Transit' || props.order.status === 'Driver Pending';
    return (
      <>
        <Box ref={componentRef}>
          <Box display="none" displayPrint="block" style={{ width: '100%'}}>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 16}}>
              <img className={classes.printLogo} src={icon} alt="Logo"></img>
              <Typography variant="h4" className={classes.textCenter}>
                Reliance United
              </Typography>
            </div>
          </Box>
          <DialogTitle id="alert-dialog-title" >Order Details: #{props.order.id}</DialogTitle>
            <DialogContent>
              <GridContainer style={{ marginBottom: 16}}>
                { (role === 'support' && supportable) && 
                  <GridItem xs={12} sm={12} md={12}>
                    <Box displayPrint="none" display="block">
                      Amigo Order Status:&nbsp;
                      <Select
                          initialValue={props.order.status}
                          value={supportStatus}
                          onChange={e => setSupportStatus(e.target.value)}>
                        <MenuItem value="Driver Pending">Driver Pending</MenuItem>
                        <MenuItem value="In Transit">In Transit</MenuItem>
                        <MenuItem value="Order Completed">Delivered</MenuItem>
                      </Select>
                      <Button
                        color="primary"
                        onClick={() => {
                          console.log('SAVE CLICKED', supportStatus)
                          dispatch(
                            supportOrderUpdate({
                              firestore,
                              orderId: props.order.id,
                              status: supportStatus,
                            })
                          );
                        }}
                      >Save</Button>
                    </Box>
                  </GridItem>
                }

                <GridItem xs={12} sm={7} md={7}>
                  <DialogContentText id="alert-dialog-description1" className={classes.header}>
                    Deliver To:
                  </DialogContentText>
                  <Typography>{props.order.author.firstName} {props.order.author.lastName}</Typography>
                  <Typography gutterBottom>{props.order.author.company ? " (" + props.order.author.company + ")" : ""}</Typography>
                  <Typography>{props.order.address.line1} {props.order.address.line2}</Typography>
                  <Typography gutterBottom>{props.order.address.city} {props.order.address.country} {props.order.address.postalCode}</Typography>
                  <Typography gutterBottom>{props.order.author.phone}</Typography>
                </GridItem>

                <GridItem xs={12} sm={5} md={5}>
                  <DialogContentText id="alert-dialog-description2">Order Summary:</DialogContentText>
                  <Typography gutterBottom>
                      {moment(props.order.createdAt.seconds * 1000).format('YYYY-MM-DD HH:mm:ss')}
                  </Typography>
                  <DialogContentText id="alert-dialog-description4" className={classes.header}>
                    Total Paid:
                  </DialogContentText>
                  <Typography gutterBottom>{CURRENCY_FORMATTER.format(totalPaid.toFixed(2))}</Typography>
                  <Typography style={{ marginBottom: 16 }}>Paid via { props.order.paymentMethod ? props.order.paymentMethod : props.order.payment }</Typography>
                </GridItem>

                <GridItem xs={12}>
                  <Divider variant="middle" style={{ marginBottom: 16 }} />
                </GridItem>
                <GridItem xs={1} className={classes.textCenter}>
                  <Tooltip title="If this column is red, the current inventory may be less than the order quantity.">
                    <DialogContentText id="alert-dialog-description3" className={classes.header}>
                      Qty.
                    </DialogContentText>
                  </Tooltip>
                </GridItem>
                <GridItem xs={1} className={classes.textCenter}>
                  <Box displayPrint="none" display="block">
                    <Tooltip title="If this column is red, the current inventory may be less than the order quantity.">
                      <DialogContentText id="alert-dialog-description3" className={classes.header}>
                        Stock
                      </DialogContentText>
                    </Tooltip>
                  </Box>
                </GridItem>
                {/* <Box displayPrint="block" display="none">
                  <GridItem xs={1} className={classes.textCenter}></GridItem>
                </Box> */}
                <GridItem xs={7} className={classes.textCenter}>
                  <DialogContentText id="alert-dialog-description3" className={classes.header} >
                    Line Items
                  </DialogContentText>
                </GridItem>
                <GridItem xs={1} className={classes.textCenter}>
                  <Tooltip title={'Y if it is a prescription drug, N otherwise. ' + 
                      'Ensure that the product is included within the "Attached Prescriptions" list below.'}>
                    <DialogContentText id="alert-dialog-description3" className={classes.header}>
                      RX?
                    </DialogContentText>
                  </Tooltip>
                </GridItem>
                <GridItem xs={2} className={classes.textCenter}>
                  <Tooltip title="The total price of this item (price × quantity).">
                    <DialogContentText id="alert-dialog-description3" className={classes.header + ' ' + classes.textRight}>
                      Price
                    </DialogContentText>
                  </Tooltip>
                </GridItem>

                {props.order.products.map((p, i) => (
                  <Fragment key={p.id}>
                    <GridItem key={i * 5 + 0} xs={1} className={classes.textCenter}>
                      <Typography className={(
                          p.quantity < p.minimumOrderQuantity || p.quantity > (props.products[p.id]?.stock || 0)
                        ) ? classes.textRed : null}>
                        <b>{p.quantity}</b>
                      </Typography>
                    </GridItem>
                    <GridItem key={i * 5 + 1} xs={1} className={classes.textCenter}>
                      <Box displayPrint="none" display="block">
                        <Typography>
                          {props.products[p.id]?.stock || 0}
                        </Typography>
                      </Box>
                    </GridItem>
                    <GridItem key={i * 5 + 2} xs={7}>
                      <Typography >{p.name}</Typography>
                    </GridItem>
                    <GridItem key={i * 5 + 3} xs={1} className={classes.textCenter}>
                      <Typography >{p.classification === "RX" ? "Y" : "N"}</Typography>
                    </GridItem>
                    <GridItem key={i * 5 + 4} xs={2} className={classes.textRight}>
                      <Typography >{CURRENCY_FORMATTER.format(p.price * p.quantity)}</Typography>
                    </GridItem>
                  </Fragment>
                ))}
                <GridItem xs={2}></GridItem>
                <GridItem xs={8}><Typography ><i>Delivery Fee</i></Typography></GridItem>
                <GridItem xs={2} className={classes.textRight}>{
                  CURRENCY_FORMATTER.format(props.order.deliveryFee != null ? props.order.deliveryFee : DELIVERY_FEE)
                }</GridItem>
                <GridItem xs={2}></GridItem>
                <GridItem xs={8}><Typography ><i>Total</i></Typography></GridItem>
                <GridItem xs={2} className={classes.textRight}>
                  <b>
                    {CURRENCY_FORMATTER.format(totalPaid.toFixed(2))}
                  </b>
                </GridItem>

                <GridItem xs={12}>
                  <Divider variant="middle" style={{ marginBottom: 16 }} />
                </GridItem>
                <GridItem xs={12} md={12}>
                  { prescriptions.length ?
                    <Typography>Attached Prescriptions</Typography> :
                    <Typography>No prescriptions attached.</Typography>
                  }
                </GridItem>

                {/* Modal version */}
                { prescriptions.length ?
                  <Box display="block" displayPrint="none">
                  <GridItem xs={12}>
                    <GridList cols={3} classes={{
                      root: classes.gridListRoot
                    }}>
                    {prescriptions.map((photo, i) => (
                      <GridListTile xs={12} sm={12} md={4} rows={1.9} classes={{
                        root: classes.gridTileRoot
                      }}>
                       <a href={photo} target="__blank">
                        <img src={photo} alt={"Prescription " + i} className={classes.gridTileImage}/>
                       </a>
                      </GridListTile>
                    ))}  
                    </GridList>
                  </GridItem>
                </Box> : null }
                {/* Print version */}
                { prescriptions.length ?
                  <Box xs={10} display="none" displayPrint="block">
                    &nbsp;&nbsp;&nbsp;&nbsp;{ prescriptions.length } image(s).
                  </Box> : null }
              </GridContainer>
            </DialogContent>
            <Box display="none" displayPrint="block" className={classes.printFooter}>
              <GridContainer>
                <GridItem xs={12} md={12}>
                  <Typography>Received By:</Typography>
                  <br/><br/>
                  <Typography>______________________</Typography>
                  <br/><br/>
                </GridItem>
              </GridContainer>
            </Box>
          </Box>
        <DialogActions>
          <Button onClick={onPrint} startIcon={<Print />}>
            Print
          </Button>
          {props.status === "Order Placed" &&
            <Button onClick={() => { props.onApprove(props.order) }} color="primary" startIcon={<Check />}>
              Approve
            </Button> }
          {props.status === "Order Placed" &&
            <Button onClick={() => { props.onReject(props.order) }} style={{ color: dangerColor }} startIcon={<Close />}>
              Reject
            </Button> }
          {props.status === "Preparing Order" &&
            <Button onClick={onActualMenuPress} startIcon={<KeyboardArrowDownIcon />}>
              Choose Shipping Option
            </Button>
          }

          {props.order.status === 'Manually Shipped' &&
            <Button onClick={() => props.manualDeliver(props.order)}>
              Mark as Delivered
            </Button>
          }
          <Button onClick={() => props.close()}>
            Close
          </Button>

          <Dialog open={menuOpen} onClose={() => {
            setMenuOpen(false);
          }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Select Courier</DialogTitle>
            <DialogContent>
              <Typography variant="body2">
                Choose a courier option from below. If you select an option <i>other than</i> Glyph Courier, the order status must be updatd manually.
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="courier" name="courier" value={selectedCourier} onChange={(e) => {
                  setSelectedCourier(e.target.value)
                }}>
                  <FormControlLabel value="glyph" control={<Radio color="primary" />} label="Reliance Concierge" />
                  <FormControlLabel value="other" control={<Radio color="primary" style={{ marginTop: -20 }} />} label={
                    <>
                      <Input
                        placeholder="Third Party"
                        disabled={selectedCourier === 'glyph'}
                        inputProps={{
                          ref: inputRef
                        }}
                        // ref={inputRef}
                        onClick={() => {
                          setSelectedCourier('other');
                        }}
                      />
                      <FormHelperText>Optional label for this courier</FormHelperText>
                    </>
                  } />
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setMenuOpen(false);
              }} color="primary">
                Cancel
              </Button>
              <Button onClick={() => {
                setMenuOpen(false);
                if (selectedCourier === 'glyph') {
                  props.onShip(props.order);
                } else {
                  props.manualShip(props.order, inputRef.current.value);
                }
              }} color="primary">
                Select
              </Button>
            </DialogActions>
          </Dialog>
        </DialogActions>
      </>
    );
}

OrderDetailModal.propTypes = {
    order: PropTypes.object,
    onApprove: PropTypes.func,
    onReject: PropTypes.func,
}

export default OrderDetailModal;
