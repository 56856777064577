import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { makeStyles } from "@material-ui/core/styles";
import  moment  from 'moment'
import {
  Button as MUIButton,
  // IconButton,
  TableContainer, Table, TableHead, TableBody,
  TablePagination,
  TableRow, TableCell,
  Typography,
  Snackbar,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  Select, MenuItem,
  Dialog,
  DialogContent, DialogTitle, DialogActions,
} from '@material-ui/core';
import {
  Alert,
} from '@material-ui/lab';
import {
  GridItem,
  Card,
  Button,
  EmptyView,
  UsersUploadStepper,
} from '../components';
import LoggedInContainer from './LoggedInContainer';
import { 
  updateUser,
} from '../actions/updateUser';

import styles from './styles';
const useStyles = makeStyles(styles);

function UsersUploadScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  useFirestoreConnect([
    {
      collection: 'users',
    }
  ]);
  const allUsers = useSelector(state => state.firestore.ordered.users);

  const roles = [
    { value: 'user', name: 'User' },
    // { value: 'driver', name: 'Driver '},
    { value: 'warehouse', name: 'Warehouse' },
    { value: 'orders', name: 'Orders' },
    { value: 'admin', name: 'Admin' },
  ]
  // useFirestoreConnect(['vendors']);
  // const vendors = useSelector(state => state.firestore.ordered.vendors) || [];
  const vendors = [{
    id: 'W7zO9ojOPCHb9dsz4EO6',
    title: 'TDS - The Diabetes Store',
  }] 

  // Auth and users
  const auth = useSelector(state => state.firebase.auth);
  const [ modalBody, setModalBody ] = useState(null);
  const [ tempRole, setTempRole ] = useState('');
  const [ tempVendor, setTempVendor ] = useState('');
  // const [ allUsers, setAll ] = useState(null)
  const [ displayUsers, setUsers ] = useState(null);
  const [ filters, setFilters ] = useState({
    // Order filters
    role: 'any',  // One: any, user, warehouse, admin
    name: '',
  })
  const [ tablePage, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(25);
  const [ filteredCount, setFilteredCount ] = useState(0);

  function filterAllUsersByFilterAndPage(all, filters, page, size) {
    const filterKeys = Object.keys(filters)
    const x = all.filter(o => {
      if (o.role === 'driver' || o.role === 'support') {
        // Exclude drivers and support completely
        return false;
      }

      return filterKeys.reduce((running, key) => {
        if (key === 'role' && filters.role !== 'any') {
          let test = o.role === undefined ? 'user' : o.role;
          return running && test === filters[key];
        } else if (key === 'name' && filters.name.length > 0) {
          if (!o.firstName || !o.lastName || !o.email) {
            return false;
          }
          return running && (
            o.firstName.toLowerCase().indexOf(filters[key].toLowerCase()) > -1 ||
            o.lastName.toLowerCase().indexOf(filters[key].toLowerCase()) > -1 ||
            o.email.toLowerCase().indexOf(filters[key].toLowerCase()) > -1);
        } else {
          return true;
        }
      }, true)
    });
    setFilteredCount(x.length);
    return x.slice((page - 1) * size, page * size)
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setFilters({
      ...filters,
      [name]: event.target.value,
    });
    setPage(1);
  }

  // Alerts
  const [ alert, setAlert ] = useState({
    type: null,
    message: ''
  });
  const showAlert = function (type, message) {
    if (type === 'close') {
      setAlert(null);
    } else {
      setAlert({ type, message });
    }
  }

  // Use Effect calls
  // useEffect(() => {
  //   const unsubscribe = firestore.collection('users')
  //     .orderBy('createdAt', 'desc')
  //     .onSnapshot(snapshot => {
  //       if (snapshot.size) {
  //         const newData = [];
  //         snapshot.forEach(doc => {
  //           newData.push(doc.data())
  //         })
  //         newData.sort((a, b) => {
  //           return a.createdAt > b.createdAt
  //         })
  //         setAll(newData)
  //       } // Else, it was empty
  //     });
  // return () => {
  //     unsubscribe();
  //   }
  // }, [firestore]);
  useEffect(() => {
    if (!!allUsers) {
      setUsers(filterAllUsersByFilterAndPage(allUsers, filters, tablePage, pageSize));
    }
  }, [allUsers, filters, tablePage, pageSize]);

  const openModal = function (which, user) {
    if (which === 'close') {
      setModalBody(null);
      setTempRole('');
    } else if (which === 'upload') {
      setModalBody(
        <UsersUploadStepper
          onClose={() => openModal('close')}
        />
      )
    } else if (which === 'role') {
      console.log('Edit role', user.id, user.role, tempRole)
      setTempRole(user.role || 'user');
      setTempVendor();
      setModalBody(
        <>
        <DialogTitle>Select New Role</DialogTitle>
        <DialogContent>
          <FormControl>
            <InputLabel id="role-select">
              <Select
                labelId="role-select"
                name="assign-role"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setTempRole(e.target.value);
                }}
                defaultValue={user.role || 'user'}
              >
                {roles.map(r => (
                  <MenuItem key={r.value} value={r.value}>{r.name}</MenuItem>
                ))}
              </Select>
            </InputLabel>
          </FormControl>
          <FormControl>
            <InputLabel id="vendor-select">
              <Select
                labelId="vendor-select"
                name="assign-vendor"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setTempVendor(e.target.value);
                }}
                defaultValue={user.role || 'user'}
              >
                {vendors.map(r => (
                  <MenuItem key={r.id} value={r.id}>{r.title}</MenuItem>
                ))}
              </Select>
            </InputLabel>
          </FormControl>
          <DialogActions>
            <MUIButton
              color="primary"
              onClick={() => {
                const updatedAt = Date.now();
                dispatch(updateUser({
                  firestore,
                  userId: user.id,
                  role: tempRole,
                  vendor: tempVendor,
                  updatedAt
                })).then(() => {
                  console.log('DISPATCHED');
                  setTempRole('');
                  setTempVendor('');
                });
              }}
            >
              Apply
            </MUIButton>
          </DialogActions>
        </DialogContent>
        </>
      )
    } else { 
      console.log('INVALID MODAL');
    }
  }

  const onChangeRowsPerPage = function (e) {
    setPageSize(e.target.value);

    // Current "top" index of old page / new page + 1
    const newPage = Math.floor(((tablePage - 1) * pageSize)  / e.target.value) + 1;
    setPage(newPage)
  }
  // Final render
  return (
    <>
      <LoggedInContainer loading={!displayUsers}>
        <GridItem xs={12} md={8} className={classes.pageTitle}>
          <Typography variant="h4">Manage Users</Typography>
        </GridItem>
        <GridItem xs={12} md={2}>
          <Button style={{ marginBottom: 8}} color="primary" onClick={() => { openModal('upload')}}>
            Upload Users
          </Button>
        </GridItem>
        <GridItem  xs={12} md={10} style={{ paddingTop: 8, verticalAlign: 'baseline' }}>
          <FormControl style={{marginRight: 16}}>
            <InputLabel>User Role</InputLabel>
            <Select name="role"
              value={filters.role}
              onChange={handleChange}
            >
              <MenuItem value={"any"}>Any</MenuItem>
              <MenuItem value={"user"}>User</MenuItem>
              {/* <MenuItem value={"driver"}>Driver</MenuItem> */}
              <MenuItem value={"warehouse"}>Warehouse</MenuItem>
              <MenuItem value={"orders"}>Order Approval</MenuItem>
              {/** Only show admins to other admins */}
              { auth.role === 'admin' && <MenuItem value={"admin"}>Admin</MenuItem> }
            </Select>
            <FormHelperText>Filter users by role</FormHelperText>
          </FormControl>

          <FormControl style={{marginRight: 16}}>
            <InputLabel>Name or Email</InputLabel>
            <Input name="name" value={filters.name} onChange={handleChange} />
            <FormHelperText>Filter users by name or email.</FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem xs={12} md={10}>
          <Card>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Birthday</TableCell>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayUsers && displayUsers.map(u => {
                    let email = u.email;
                    if (u.email) {
                      const atIndex = u.email ? u.email.indexOf('@') : -1;
                      email = u.email[0] + "*".repeat(atIndex - 1) + u.email.slice(atIndex - 1) 
                    } else {
                      email = "N/A";
                    }
                    if (!u.id) {
                      console.log('NO ID', u);
                    }
                    const phone = u.phone ? u.phone.slice(0, 4) + "XXX" + u.phone.slice(7) : "N/A";
                    const birthdate = u.birthday ? moment(u.birthday).format("MMMM Do YYYY") : "N/A";
                    return (
                      <TableRow key={u.id}>
                        <TableCell>{u.firstName} {u.lastName}</TableCell>
                        <TableCell>{phone}</TableCell>
                        <TableCell>{email}</TableCell>
                        <TableCell>{birthdate}</TableCell>
                        <TableCell
                          style={{
                            transform: 'capitalize',
                            cursor: 'pointer',
                          }}
                          onClick={() => openModal('role', u)}
                        >{u.role || "User"}</TableCell>
                      </TableRow>
                    )
                  })}
                  {!displayUsers && <EmptyView colSpan={5} style={{ textAlign: 'center', padding: 24 }}/>}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={tablePage - 1}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredCount}
              onChangePage={(e, newPage) => { setPage(newPage + 1)}}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Card>
        </GridItem>
      </LoggedInContainer>
      <Snackbar className={classes.toast} open={alert != null && alert.type != null} autoHideDuration={5000} onClose={() => showAlert('close')}>
        <Alert onClose={() => { showAlert('close')}} severity="success">
          {alert && alert.message}
        </Alert>
      </Snackbar>
      <Dialog open={modalBody !== null}
        maxWidth="md"
        onClose={() => openModal('close')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        {modalBody}
      </Dialog>
    </>
  )
}

export default UsersUploadScreen;
