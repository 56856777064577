const authReducer = (
    state = {},
    action
) => {
    // Note to self: do we need this if firebase auth has its own reducer already? Probably not?
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                myUser: action.payload.user,
                // user: action.user
            }
        case 'REGISTER':
            return {
                ...state,
                myUser: action.payload.user,
                // user: action.user
            }
        case 'BATCH_USER_UPLOAD':
            return {
                ...state,
                batchUsersResults: action.payload
            }
        default:
            return state;
    }
}

export default authReducer;
