import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoggedInContainer from './LoggedInContainer';
import { CURRENCY_FORMATTER } from '../constants';
import { 
    makeStyles,
    Typography,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Tooltip,
    IconButton,
    Dialog,
} from '@material-ui/core';
import {
    GridItem,
    Card,
    EmptyView
  } from '../components';
import styles from './styles';
import CreditCardRounded from '@material-ui/icons/CreditCardRounded'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import moment from 'moment';

const useStyles = makeStyles(styles);

const OrdersRefundScreen = () => {
    const classes = useStyles();
    const firestore = useFirestore();
    const [modalBody, setModalBody] = useState(null);
    
    useFirestoreConnect([
        {
          collection: 'refund_request',
          where: [['refundStatus', '==', 'PENDING']],
        }
      ]);

    const refunds = useSelector(state => state.firestore.ordered.refund_request) || [];
    console.log('[ORDERS WITH REFUND STATUS]', refunds);


    const openModal = (which, i, extra) => {

        if (which === 'open') {
          setModalBody(
          
          )
        } else {
          setModalBody(null);
        }
      }

    return (
        <LoggedInContainer>
            {/* <GridItem xs={12} md={8} className={classes.pageTitle}>
                <Typography variant="h4">Refund Order</Typography>
            </GridItem>

            <GridItem xs={12} md={10}>
                <Card>
                    <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                        <TableRow>
                            <TableCell>Order ID</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Payment Method</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Transaction Date</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {refunds && refunds.map((refund, i) => (
                            <TableRow key={i}> 
                                <TableCell>{refund.id}</TableCell>
                                <TableCell>{refund.name}</TableCell>
                                <TableCell>{refund.payment}</TableCell>
                                <TableCell>{CURRENCY_FORMATTER.format(refund.total)}</TableCell>
                                <TableCell>{refund.refundStatus}</TableCell>
                                <TableCell>{moment(refund.createdAt.seconds * 1000).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                <TableCell>
                                    <Tooltip title={`Refund Customer: ${refund.name}, Order ID: ${refund.id}`}>
                                        <IconButton>
                                            <CreditCardRounded />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                        {refunds.length === 0 && <EmptyView colSpan={9} style={{ padding: 24 }}/>}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Card>
            </GridItem>
            <Dialog 
             open={modalBody}
             onClose={() => openModal('close')}
             maxWidth="md"
             aria-labelledby="alert-dialog-title"
             aria-describedby="alert-dialog-description"
            >
                {modalBody}
            </Dialog> */}
        </LoggedInContainer>
    )
};

export default OrdersRefundScreen;
