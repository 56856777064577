import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';

import config from './config';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import what from './store';
import router from './router'; 
import PrivateRoute from './components/PrivateRoute';
import NotFound from './views/404';

import reportWebVitals from './reportWebVitals';
import './index.css';
const { configureStore, firebase } = what;

const store = configureStore();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider
          firebase={firebase}
          config={config.firestore}
          dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}>
        <Router>
          <Switch>
            {router.map(route => {
              const { path, component, ...rest } = route;
              if (route.private) {
                return (
                  <PrivateRoute path={path} key={path} comp={component} />
                )
              } else {
                return (
                  <Route path={path} key={path} component={component} {...rest} />
                );
              }
            })}
            <Route path="/:else" component={NotFound} />
          </Switch>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
