import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    DialogContent,
    Typography,
    TableContainer, Table,
    TableHead, TableBody,
    TableRow, TableCell,
    Stepper, Step, StepButton,
    Select, MenuItem,
} from '@material-ui/core';
import CSVReader from 'react-csv-reader';
import {
  GridContainer, GridItem,
} from '../components';
import batchCreateUsers from '../actions/batchCreateUsers';
import { VENDOR_ID } from '../constants';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
  return ['Choose File', 'Verify', 'Upload'];
}

function generatePassword() {
  return 'securerandompassword';
}

function UserUploadStepper(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firestore = useFirestore();

  // useFirestoreConnect(['vendors']);
  // const vendors = useSelector(state => state.firestore.ordered.vendors) || [];
  const vendors = [{
    id: 'W7zO9ojOPCHb9dsz4EO6',
    title: 'TDS - The Diabetes Store',
  }]

  const [ tempUsers, setTempUsers ] = useState([]);
  const [ vendor, setVendor ] = useState(VENDOR_ID);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const steps = getSteps();
  const batchResults = useSelector(state => state.batchUserResults);
  console.log('BATCH RESULTS', batchResults);

  function onFileLoaded(data) {
    const last = data.length - 1;
    if (data.length && data[last].length !== 0) {
      // Ignore last row of CSV, it was empty.
      data = data.slice(0, last);
    }
    console.log('UPLOADED!', data);
    if (data.length) {
      data = data.map(d => ({
        // id: d[4],
        firstName: d[0], 
        lastName: d[1],
        email: d[4],
        phone: d[3],
        company: d[2],
        role: d[5] || 'user',
        password: generatePassword(),
      }));
    }
    setTempUsers(data.slice(1))
  }

  useEffect(() => {
    // Only upload when we move into the third screen
    let timeoutCanceller = undefined;
    if (activeStep === totalSteps() - 1) {
      setLoading(true);
      // DEBUG - try to upload only two items as a test
      console.log('Dispatch', tempUsers.length);
      dispatch(batchCreateUsers(tempUsers.slice(0, 1), firestore)).then(result => {
        console.log('RESULT THEN', result);
        setTimeout(() => {
          setLoading(false);
          const newCompleted = new Set(completed);
          newCompleted.add(activeStep);
          setCompleted(newCompleted);
          setTempUsers([])
        }, 1000);
        setTimeout(() => {
          props.onClose();
        }, 10 * 1000);
      });
    }
    return () => {
      clearTimeout(timeoutCanceller);
    }
  }, [activeStep]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div id="woot" className={classes.textCentter}>
            <Typography variant="body2">Select which vendor these users will belong to.</Typography>
            <Select name="vendor" 
                value={vendor}
                onChange={e => setVendor(e.target.value)}
              >
                { vendors.map(v => (
                  <MenuItem value={v.id} key={v.id}>{v.title}</MenuItem>
                )) }
            </Select>
            <div>&nbsp;</div>
            <Typography variant="body2">Please see the Users template file &nbsp;
              <a href="https://drive.google.com/uc?export=download&id=1t93WFL4lL0zLW_1kc4m-wfeAR4OFB2d1">here</a>.
            </Typography>
            <CSVReader onFileLoaded={onFileLoaded}/>
            <div>&nbsp;</div>
          </div>
        );
      case 1:
        return (
          <GridContainer>
            <GridItem xs={12}>
              <Typography>This will create user accounts for the following </Typography>
            </GridItem>

            <TableContainer>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow xs={12}>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Pre-generated Password</TableCell>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tempUsers.map(tmp => (
                    <TableRow key={tmp.id}>
                      <TableCell>{tmp.firstName}</TableCell>
                      <TableCell>{tmp.lastName}</TableCell>
                      <TableCell>{tmp.email}</TableCell>
                      <TableCell>{tmp.phone}</TableCell>
                      <TableCell>{tmp.password}</TableCell>
                      <TableCell style={{ transform: 'capitalize' }}>{tmp.role}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridContainer>
        );
      case 2:
        return (
          <Typography>"Uploading..."</Typography>
        );
      default:
          return "Unknown step";
    }
  }  
  const totalSteps = () => {
      return getSteps().length;
  };
  const completedSteps = () => {
    return completed.size;
  };  
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    // ALSO COMPLETE on clicking next
    // handleComplete();
    setActiveStep(newActiveStep);
  };
  const handleBack = () => {
    if (activeStep === 0) {
      props.onClose();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== totalSteps()) {
      handleNext();
    }
  };
  const handleReset = () => {
    setActiveStep(0);
    setCompleted(new Set());
  };
  function isStepComplete(step) {
    return completed.has(step);
  }

  return (
    <div className={classes.root} style={props.style}>
      <DialogContent>
        <Stepper alternativeLabel nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const buttonProps = {};
            
            return (
              <Step key={label} {...stepProps}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={isStepComplete(index)}
                  {...buttonProps}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <div>
              <Typography className={classes.instructions}>
              Upload completed; please verify that the product list has been updated. 
              This window will close in 10 seconds.
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
            {getStepContent(activeStep)}
            <div>
              <Button onClick={handleBack} className={classes.button}>
                {activeStep === 0 ? 'Cancel' : 'Back'}
              </Button>
              <Button variant="contained" color="primary" onClick={handleComplete}
                  disabled={loading || tempUsers.length === 0}>
                {activeStep === totalSteps() - 1 ? 'Please Wait' : 'Next'}
              </Button>
              {/* {activeStep !== steps.length && */}
            </div>
          </div>
          )}
        </div>
      </DialogContent>
    </div>
  )
}

export default UserUploadStepper;
