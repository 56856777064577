import React, { useEffect, useState } from 'react';
import UserVerifyModal from './modals/UserVerifyModal';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import LoggedInContainer from './LoggedInContainer';
import { 
    Typography,
    makeStyles,
    TableContainer, 
    Table, 
    TableRow, 
    TableHead, 
    TableBody, 
    TableCell,
    TablePagination,
    Tooltip,
    IconButton,
    Dialog,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';
import {
  Alert
} from '@material-ui/lab';
import Visibility from "@material-ui/icons/Visibility"
import {
    GridItem,
    EmptyView,
    Card,
  } from '../components';
import moment from 'moment';
import {
  approveUserID,
  rejectUserID,
} from '../actions/verifyUser';

import styles from './styles';
import buttonStyle from '../assets/jss/material-kit-react/components/buttonStyle';

const useStyles = makeStyles(styles);

const UsersVerifyScreen = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const [modalBody, setModalBody] = useState(null);
    const [alert, setAlert] = useState({ type: null, message: '' });
    const [ displayUsers, setUsers ] = useState(null);
    const [ filters, setFilters ] = useState({
      status: 'PENDING',
    });
    const [ tablePage, setPage ] = useState(1);
    const [ pageSize, setPageSize ] = useState(25);
    const [ filteredCount, setFilteredCount ] = useState(0);
    const [ allUsers, setAllUsers ] = useState([]);
    
    useFirestoreConnect([
      {
        collection: 'users',
        where: [['discountVerified', '==', 'PENDING']],
        storeAs: 'users_pending',
      },
      {
        collection: 'users',
        where: [['discountVerified', '==', 'APPROVED']],
        storeAs: 'users_approved'
      },
    ]);
    
    const usersPending = useSelector(state => state.firestore.ordered.users_pending);
    const usersApproved = useSelector(state => state.firestore.ordered.users_approved);
    
    useEffect(() => {
      if(usersPending && usersApproved) {
        setAllUsers([...usersPending, ...usersApproved])
      }
    }, [usersPending, usersApproved]);

    useEffect(() => {
      if (!!allUsers) {
        setUsers(filterAllUsersByFilterAndPage(allUsers, filters, tablePage, pageSize));
      }
    }, [allUsers, filters, tablePage, pageSize]);
    
    const onChangeRowsPerPage = function (e) {
      setPageSize(e.target.value);
  
      // Current "top" index of old page / new page + 1
      const newPage = Math.floor(((tablePage - 1) * pageSize)  / e.target.value) + 1;
      setPage(newPage)
    }

    const onApproveUserHandler = (i) => {
      const user = displayUsers[i];
      const updatedAt = new Date();
      console.log('user', user, 'index', i);
      dispatch(approveUserID({ firestore, userID: user.id, updatedAt })).then(() => {
        showAlert({ type: 'success', message: 'PWD / SC Verified'});
        setModalBody(null);
      })
    };

    const onRejectUserHandler = (i) => {
     const user = displayUsers[i];
     const updatedAt = new Date();
     console.log('user', user, 'index', i);
    dispatch(rejectUserID({firestore, userID: user.id, updatedAt})).then(() => {
      showAlert({ type: 'warning', message: `PWD ID / SC ID REJECTED`});
      setModalBody(null);
    })
  }

    const openModal = (which, i, extra) => {
      const user = displayUsers[i];
      if (which === 'open') {
        console.log('user selected', user);
        setModalBody(
        <UserVerifyModal
        user={user}
        approve={() => onApproveUserHandler(i)}
        reject={() => onRejectUserHandler(i)}
        close={() => openModal('close')}
        />
        )
      }
      else {
        setModalBody(null);
      }
    }

    const showAlert = ({ type, message}) => {
    if (type === 'success') {
      setAlert({ type, message });
    } else if (type === 'warning' || type === 'danger') {
      setAlert({ type: 'warning', message });
    } else if (type === 'close') {
      setAlert({
        type: null,
        message: ''
      })
    } else {
      console.warn('Invalid alert woo');
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setFilters({
      ...filters,
      [name]: event.target.value,
    });
    // TODO - Do I ALWAYS have to reset the page to 1? It seems like YES...
    setPage(1);
  }

  function filterAllUsersByFilterAndPage(all, filters, page, size) {
    const filterKeys = Object.keys(filters);
    const x = all.filter(o => {
      return filterKeys.reduce((running, key) => {
        console.log(key, 'current key')
        if (key === 'status' && filters[key] !== 'any') {
          console.log(filters[key], 'pasok')
          return running && o.discountVerified === filters[key];
        }  else {
          return true;
        }
      }, true)
    });
    setFilteredCount(x.length);
    return x.slice((page - 1) * size, page * size)
  }

  console.log(filters, filters.status);

    return (
        <LoggedInContainer>
         <GridItem xs={12} md={8} className={classes.pageTitle}>
          <Typography variant="h4">Verify Users</Typography>
        </GridItem>

        <GridItem xs={12} md={8} style={{ paddingTop: 28 }}>
          <FormControl style={{marginRight: 16}}>
            <InputLabel>Verification Status</InputLabel>
            <Select name="status"
              value={filters.status}
              onChange={handleChange}
            >
              <MenuItem value={"any"}>Any</MenuItem>
              <MenuItem value={"PENDING"}>Pending</MenuItem>
              <MenuItem value={"APPROVED"}>Approved</MenuItem>
            </Select>
            <FormHelperText>Filter users by verification status</FormHelperText>
          </FormControl>
        </GridItem>
        
        <GridItem xs={12} md={8}>
          <Card>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Birthday</TableCell>
                    <TableCell>Verification Status</TableCell>
                    <TableCell>Discount Relation</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {displayUsers && displayUsers.map((u, i)=> {
                    let email = u.email;
                    if (u.email) {
                      const atIndex = u.email ? u.email.indexOf('@') : -1;
                      email = u.email[0] + "*".repeat(atIndex - 1) + u.email.slice(atIndex - 1) 
                    } else {
                      email = "N/A";
                    }
                    if (!u.id) {
                      console.log('NO ID', u);
                    }
                    const phone = u.phone ? u.phone.slice(0, 4) + "XXX" + u.phone.slice(7) : "N/A";
                    const birthdate = u.birthday ? moment(u.birthday).format("MMMM Do YYYY") : "N/A";
                    return (
                      <TableRow key={u.id}>
                        <TableCell>{u.firstName} {u.lastName}</TableCell>
                        <TableCell>{phone}</TableCell>
                        <TableCell>{email}</TableCell>
                        <TableCell>{birthdate}</TableCell>
                        <TableCell>{u.discountVerified}</TableCell>
                        <TableCell>{u.discountRelation ? u.discountRelation : "N/A"}</TableCell>
                        <TableCell>
                          <Tooltip title="verify users request for pwd discount">
                            <IconButton aria-label="action" onClick={() => openModal('open', i)}> 
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        {!displayUsers && <EmptyView colSpan={5} style={{ textAlign: 'center', padding: 24 }}/>}
                      </TableRow>
                    )
                  })}
                  </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={tablePage - 1}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredCount}
              onChangePage={(e, newPage) => { setPage(newPage + 1)}}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Card>
        </GridItem>
        <Dialog 
          open={modalBody}
          onClose={() => openModal('close')}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          {modalBody}
        </Dialog>
        <Snackbar open={alert.type !== null} autoHideDuration={6000} onClose={() => showAlert({ type: 'close' })}>
        <Alert onClose={() => showAlert({ type: 'close' })} severity={alert.type || 'info'}>
          {alert.message}
        </Alert>
      </Snackbar>
    </LoggedInContainer>
    )
}

export default UsersVerifyScreen;
