const approveUserID = ({
    firestore,
    userID,
    updatedAt,
}) => async (dispatch, _, { getFireBase }) => {
    console.log('PRE-APPROVE', firestore, userID, updatedAt);
    return firestore.update(`users/${userID}`, {
        discountVerified: "APPROVED",
        updatedAt: firestore.FieldValue.serverTimestamp(),
    }).then(() => {
        console.log('USER VERIFIED', userID, updatedAt);
        dispatch({
            type: 'ID_VERIFIED',
            payload: {
                userID,
                updatedAt,
            }
        })
    })
};

const rejectUserID = ({
    firestore,
    userID,
    updatedAt,
}) => async (dispatch, _, { getFireBase }) => {
    console.log('PRE-REJECT', firestore, userID, updatedAt);
    return firestore.update(`users/${userID}`, {
        discountVerified: "REJECTED",
        updatedAt: firestore.FieldValue.serverTimestamp(),
    }).then(() => {
        console.log('USER REJECTED', userID, updatedAt);
        dispatch({
            type: 'ID_REJECTED',
            payload: {
                userID,
                updatedAt,
            }
        })
    })
};


export {
    approveUserID,
    rejectUserID,
}