import React, { useState, useEffect } from 'react';
import {
    Box,
    DialogTitle,
    DialogContent,
    CircularProgress,
    Chip,
    makeStyles,
} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import styles from '../styles';

const useStyles = makeStyles(styles);

const ProductPhotosModal = (props) => {
    const { product } = props;
    const classes = useStyles();
    const photos = product.photos ? product.photos.split(",") : [];
    const allPhotos = [];
    allPhotos.push(...photos, product.photo);
    console.log(product.description, 'description');
    const tags = product.tags ? product.tags.split(",") : [];
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [])

    const photoLoading = (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress size={100}/>
        </div>
    );

    return (
        <Box>
             <DialogTitle style={{textAlign: 'center'}}>{product.name}</DialogTitle>
             <DialogContent style={{textAlign: 'center', overflow: 'hidden'}}>
             { allPhotos.length > 0 ? 
                <Carousel
                 autoPlay={false}
                 animation="slide"
                next={(next, active) => { 
                    setLoading(true) ;
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000)
                }}
                prev={(prev, active) => { 
                    setLoading(true) ;
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000)
                }}
                >
                   {allPhotos && allPhotos.map(photo => (
                       <>
                       {loading ? photoLoading :                         
                            <img src={photo} alt={product.name} style={{ width: '100%', maxWidth: '350px'}}/>
                       }
                       </>
                   ))}
               </Carousel>
               :
               <h4 style={{textAlign: 'center'}}>N/A</h4>
             }
             <div className={classes.chipStyle}>
                {tags && tags.map((tag) => {
                    return <Chip 
                            label={tag} 
                            className={classes.chipStyle} 
                            clickable={true}
                            />
                })}
             </div>
             <p style={{textAlign: 'justify', padding: '0px 20px', whiteSpace: 'pre-wrap'}}>{product.description.trim()}</p>
             </DialogContent>
        </Box>
    )
}

export default ProductPhotosModal;
