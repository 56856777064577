import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import {
  ShoppingBasket,
  Room,
  Fingerprint
} from "@material-ui/icons";
// core components
import {
  GridContainer, GridItem,
  InfoArea
} from "../../components";

import styles from "../../assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Features</h2>
          <h5 className={classes.description}>
            Monitor orders, track the couriers, and more.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Inventory Management"
              description="An easy interface to keep your stock in order. Upload, download, or enter products manually."
              icon={ShoppingBasket}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Order Status"
              description="Manage orders and track delivery for each customer. For prescription orders, view the prescriptions attached."
              icon={Room}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Secure"
              description="Leveraging industry-leading security features to keep your business safe and running."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
