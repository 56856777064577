import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    DialogContent,
    CircularProgress,
    Stepper, Step, StepButton,
    TableContainer, Table,
    TableBody, TableHead,
    TableRow, TableCell,
    Typography,
} from '@material-ui/core';
import CSVReader from 'react-csv-reader';
import batchUploadProducts from '../actions/batchUploadProducts';

const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
  return ['Choose File', 'Verify', 'Upload'];
}

function ProductsUploadStepper(props) {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const classes = useStyles();
  const [ tempProducts, setTempProducts ] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [ loading, setLoading ] = useState(false);
  const steps = getSteps();

  // Load categories from firestore
  useFirestoreConnect({ collection: 'vendor_categories' });
  const categories = useSelector((state) => state.firestore.vendor_categories);
  const categoryMap = {};
  if (categories) {
    console.log('Mapping categories', categories);
    Object.keys(categories).map(cat => {
      categoryMap[categories[cat].name] = categories[cat];
      return null;
    });
  }
  useEffect(() => {
    // Only upload when we move into the third screen
    let timeoutCanceller = undefined;
    if (activeStep === totalSteps() - 1) {
      setLoading(true);
      // DEBUG - try to upload only two items as a test
      dispatch(batchUploadProducts(tempProducts, firestore)).then(() => {
        setTimeout(() => {
          setLoading(false);
          const newCompleted = new Set(completed);
          newCompleted.add(activeStep);
          setCompleted(newCompleted);
          setTempProducts([]);
        }, 1000);
        timeoutCanceller = setTimeout(() => {
          props.onClose();
        }, 10 * 1000);
      });
    }
    return () => {
      clearTimeout(timeoutCanceller);
    }
  }, [activeStep]);

  function onFileLoaded(data) {
    const last = data.length - 1;

    setTempProducts(data.slice(1).filter(p => {
      // Filter empty rows
      return p.length === 17 || p.length === 18;
    }).map(p => {
      // console.log(p, 'product?');
      console.log('PRODUCT', p[4].length, p[16], p);
      const objectified = {
        id: p[0] || p[4],
        classification: p[1],
        categoryID: categoryMap[p[2]] || p[2],
        name: p[6],
        description: p[7],
        photo: p[3],
        code: p[4],
        stock: p[5],
        packaging: p[9],
        quantityPerBox: p[8],
        unitOfMeasure: p[11],
        minimumOrderQuantity: p[10],
        companyA: p[12],
        companyB: p[13],
        companyC: p[14],
        retail: p[15],
        tags: p[16],
        photos: p[17],
      } 
      return objectified;
    }))
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div style={{marginBottom: 16}}>
            <Typography>Please see the Products template file&nbsp;
                <a href="https://drive.google.com/uc?export=download&id=1c-HrOk0EuJs_Z4GdBV2Ou6bPeiqK_Zqp">here</a>
            .</Typography>
            <CSVReader onFileLoaded={onFileLoaded}/>
          </div>
        );
      case 1:
        return (
          <div style={{marginBottom: 16 }}>
            <div xs={12}>
              <Typography>This will create/update the following {tempProducts.length} products:</Typography>
            </div>
            <TableContainer>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow xs={12}>
                      <TableCell>Product Code</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Amt. in Stock</TableCell>
                      <TableCell>Min. Order Qty.</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                  {tempProducts.map((tmp, i) => (
                    <TableRow key={i || tmp.code} xs={12}>
                      <TableCell>{tmp.code}</TableCell>
                      <TableCell>{tmp.name}</TableCell>
                      <TableCell>{tmp.stock}</TableCell>
                      <TableCell>{tmp.minimumOrderQuantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      case 2:
        return (
          <div style={{ textAlign: 'center', marginBottom: 16 }}>
            <Typography>Uploading...</Typography>
            <CircularProgress />
          </div>
        );
      default:
          return "Unknown step";
    }
  }  
  const totalSteps = () => {
      return getSteps().length;
  };

  const completedSteps = () => completed.size;
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

      console.log('Handle Next:', newActiveStep);
      setActiveStep(newActiveStep);
  };
  const handleBack = () => {
    if (activeStep === 0) {
      props.onClose();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== totalSteps()) {
      handleNext();
    }
  };
  function isStepComplete(step) {
    return completed.has(step);
  }

  console.log('Next disabled', loading, tempProducts === [])
  return (
    <>
    <Stepper alternativeLabel nonLinear activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepProps = {};
        const buttonProps = {};
        return (
          <Step key={label} {...stepProps}>
            <StepButton
              onClick={handleStep(index)}
              completed={isStepComplete(index)}
              {...buttonProps}
            >
              {label}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
    <DialogContent className={classes.root}>
      <div>
        {allStepsCompleted() ? (
          <div>
            <Typography className={classes.instructions}>
              Upload completed; please verify that the product list has been updated. 
              This window will close in 10 seconds.
            </Typography>
            <Button onClick={() => props.onClose()}>Close</Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            <div>
              <Button onClick={handleBack} className={classes.button}>
                {activeStep === 0 ? 'Cancel' : 'Back'}
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                Next
              </Button> */}

              <Button variant="contained" color="primary" onClick={handleComplete}
                  disabled={loading || tempProducts.length === 0}>
                {activeStep === totalSteps() - 1 ? 'Please Wait' : 'Next'}
              </Button>
              {/* {activeStep !== steps.length && */}
            </div>
          </div>
        )}
      </div>
    </DialogContent>
    </>
  )
}

export default ProductsUploadStepper;
