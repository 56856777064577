/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { 
  DeleteIcon,
  IconButton
} from "@material-ui/icons"
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Tooltip } from "@material-ui/core";

// @material-ui/icons
import AccountCircle from '@material-ui/icons/AccountCircle';
import Assignment from '@material-ui/icons/Assignment';
import Inbox from "@material-ui/icons/Inbox";
import Group from "@material-ui/icons/Group";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
// core components
import {
  Badge,
  CustomDropdown,
  Button
} from "../";

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const firebase = useFirebase();
  const auth = useSelector(state => state.firebase.auth);
  const history = useHistory();
  const firestore = useFirestore();
  const [ role, setRole ] = useState('');
  const handleNavClick = (route) => {
    history.push("/" + route);
  }

  const classes = useStyles();
  useEffect(() => {
    if (auth.uid) {
      firestore.collection('users').doc(auth.uid).get().then(user => {
        setRole(user.data().role);
      });
    }
  }, [ auth.uid ]);
  
  if (auth.isLoaded === false || auth.isLoaded === true && auth.isEmpty === true) {
    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Button color="transparent"
            className={classes.navLink}
            onClick={() => handleNavClick("login")}
          >
            <AccountCircle className={classes.icons} /> Sign In
          </Button>
        </ListItem>
      </List>
    );
  }

  return (
      <List className={classes.list}>
        { (role === 'superadmin' || role === 'admin' || role === 'warehouse') &&
        <ListItem className={classes.listItem}>
          <Button color="transparent"
            className={classes.navLink}
            onClick={() => handleNavClick("inventory")}
          >
            <Assignment className={classes.icons} /> Inventory
          </Button>
        </ListItem> }
        { (role === 'superadmin' || role === 'admin' || role === 'orders' || role === 'support') &&
        <ListItem className={classes.listItem}>
          <Button color="transparent"
            className={classes.navLink}
            onClick={() => handleNavClick("orders")}
            >
            <Inbox className={classes.icons} /> Orders&nbsp;
            { props.orderBadge ? <Badge color="info">{props.orderBadge}</Badge> : null }
          </Button>
        </ListItem> }
        { role === 'superadmin' &&
        <ListItem className={classes.listItem}>
          <Button color="transparent"
            className={classes.navLink}
            onClick={() => handleNavClick("users/upload")}
          >
            <Group className={classes.icons} /> Users
          </Button>
        </ListItem> }

        { (role === 'superadmin' || role === 'admin' ) && 
        <ListItem className={classes.listItem}>
          <Button color="transparent"
            className={classes.navLink}
            onClick={() => handleNavClick("users/verify")}
          >
            <AssignmentInd className={classes.icons} /> Verify
          </Button>
        </ListItem> }

        <ListItem className={classes.listItem}>
          { auth.isEmpty ?
            <Button color="transparent"
              className={classes.navLink}
              onClick={() => handleNavClick("login")}
            >
              <NotificationImportant className={classes.icons} />Log In
            </Button> :
            <CustomDropdown
              noLiPadding
              buttonProps={{
                className: classes.navLink,
                color: "transparent"
              }}
              buttonIcon={AccountCircle}
              dropdownList={[
                (role === 'superadmin' || role === 'admin') &&
                <Link to="/settings" className={classes.dropdownLink}>
                  Settings
                </Link>,
                <Link onClick={() => {
                  firebase.logout().then(() => {
                    history.push('/');
                  });
                }} to="/" className={classes.dropdownLink}>
                  Sign Out
                </Link>
              ]}
            />
          }
        </ListItem>
      </List>
  );
}
