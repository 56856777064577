import {
    createStore, applyMiddleware,
    compose
} from 'redux';
import thunk from 'redux-thunk';
import config from './config';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { getFirebase } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore';
import rootReducer from './reducers'

firebase.initializeApp(config.firebase);
firebase.firestore();


function configureStore(state = {
    auth: {},
    orders: {}
}) {
    return createStore(
        rootReducer,
        state,
        compose(
            applyMiddleware(
                thunk.withExtraArgument({ getFirebase, getFirestore })
            )
        )
    );
}


const x = {
    configureStore,
    firebase,
};
export default x;