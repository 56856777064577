import React from 'react';
import {
    Button,
    DialogTitle, DialogActions,
    DialogContent, DialogContentText
} from '@material-ui/core';

import { dangerColor } from '../../assets/jss/material-kit-react';

function OrderRejectReason(props) {
  function onSelect(reason) {
      props.rejectWithReason(reason, props.order)
      props.onClose();
  }

  return (
    <div>
      <DialogTitle id="alert-dialog-title">Reject Order?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This order will be rejected. Please select a rejection reason from below.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: dangerColor }} onClick={() => {
          onSelect("no-prescription")
        }} autoFocus>
          No Prescription
        </Button>
        <Button style={{ color: dangerColor }} onClick={() => {
          onSelect("duplicate");
        }}>
          Duplicate
        </Button>
        <Button onClick={() => { props.onClose() }}>
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
}

export default OrderRejectReason;
