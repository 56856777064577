import {
    successColor,
    blackColor,
    whiteColor,
    grayColor,
    hexToRgb
} from "../../material-kit-react.js";

const opacity = "DD";

// From https://github.com/creativetimofficial/material-dashboard-react/blob/master/src/assets/jss/material-dashboard-react/views/dashboardStyle.js
const dashboardStyle = {
    successText: {
      color: successColor
    },
    upArrowCardCategory: {
      width: 16,
      height: 16
    },
    stats: {
      color: blackColor + opacity,
      display: "inline-flex",
      fontSize: "12px",
      lineHeight: "22px",
      "& svg": {
        top: 4,
        width: 16,
        height: 16,
        position: "relative",
        marginRight: 3,
        marginLeft: 3
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        top: 0,
        fontSize: "16px",
        position: "relative",
        marginRight: 3,
        marginLeft: 3
      }
    },
    cardCategory: {
      color: blackColor + opacity,
      margin: 0,
      fontSize: "14px",
      marginTop: 0,
      paddingTop: 10,
      marginBottom: 4
    },
    cardCategoryWhite: {
      color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
      margin: 0,
      fontSize: "14px",
      marginTop: 0,
      marginBottom: 0
    },
    cardTitle: {
      color: blackColor + "AF",
      marginTop: 0,
      minHeight: "auto",
      fontWeight: "300",
      fontSize: "1.3rem",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: 3,
      textDecoration: "none",
      "& small": {
        color: grayColor + "EE",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    cardTitleWhite: {
      color: whiteColor,
      marginTop: 0,
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: 3,
      textDecoration: "none",
      "& small": {
        color: grayColor + "EE",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };
  
export default dashboardStyle;
