import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

function EmptyView(props) {
    return (
        <TableRow { ...props }>
            <TableCell colSpan={props.colSpan} style={{ textAlign: 'center'}}>
                <Typography variant="body2">There doesn't seem to be anything here.</Typography>
            </TableCell>
        </TableRow>
    )
}

export default EmptyView;