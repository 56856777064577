const ordersReducer = (
    state = {},
    action
) => {
    let originalIdx;
    let firestore2 = { ...state.firestore };
    if (action.payload && action.payload.id && firestore2 && firestore2.ordered) {
        console.log('Finding originalIdx')
        for (let i = 0; i < firestore2.ordered.restaurant_orders.length; i++) {
            if (firestore2.ordered.restaurant_orders[i] === action.payload.id) {
                originalIdx = i;
                break;
            }
        }
    } 
    console.log(action.type, action.payload, JSON.stringify(state.firestore));
    switch (action.type) {
        case '': {
            return {
                ...state,
                currentOrder: null
            }
        }
        case 'APPROVE_ORDER':
            // nested data sux
            if (originalIdx !== undefined) {
                firestore2.ordered = [].concat(firestore2.ordered);
                firestore2.ordered[originalIdx].status = action.payload.status;
                firestore2.ordered[originalIdx].updatedAt = action.payload.updatedAt;
                firestore2.data = { ...firestore2.data };
                firestore2.data[action.payload.id].status = action.payload.status;
                firestore2.data[action.payload.id].reason = action.payload.reason;
                firestore2.data[action.payload.id].updatedAt = action.payload.updatedAt;
            }
            console.log("OKKKKK!", originalIdx, firestore2.ordered, firestore2.data);
            return {
                ...state,
                // firestore: firestore2
            }
        case 'REJECT_ORDER':
            if (originalIdx !== undefined) {
                firestore2.ordered = [].concat(firestore2.ordered);
                firestore2.ordered[originalIdx].status = action.payload.status;
                firestore2.ordered[originalIdx].reason = action.payload.reason;
                firestore2.ordered[originalIdx].updatedAt = action.payload.updatedAt;
                firestore2.data = { ...firestore2.data };
                firestore2.data[action.payload.id].status = action.payload.status;
                firestore2.data[action.payload.id].reason = action.payload.reason;
                firestore2.data[action.payload.id].updatedAt = action.payload.updatedAt;
            }
            console.log('NOT OK!!!!', originalIdx);
            return {
                ...state,
                // firestore: firestore2
            }
        case 'MANUAL_SHIP_ORDER':
        case 'MANUAL_DELIVERY_ORDER':
            console.log('Manual update', action.type);
            return state;
        case 'SUPPORT_ORDER':
            console.log('Support order update', action.payload);
            return state;
    }
    return state;
}

export default ordersReducer;
