import React from 'react';
import classNames from "classnames";
import {
    Typography
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import {
    Header, HeaderLinks,
    GridContainer, GridItem,
    Footer,
} from '../components';
import {
    Container,
} from '@material-ui/core';
import { APPLICATION_NAME } from '../constants';
import styles from "../assets/jss/material-kit-react/views/landingPage.js";
import tds from '../assets/img/tds-banner.png';

const useStyles = makeStyles(theme => ({
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3",
      },
      mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        },
      },
      body: {
        marginTop: 36,
        padding: 16,
        marginBottom: 16,
        textAlign: 'justify',
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            padding: 16,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 13,
        },
        },
        termsTitle: {
            marginTop: '15px',
            fontWeight: '700',
            textAlign: 'center',
        },
        unorderedList: {
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 15,
            },
        },
        container3: {
        backgroundColor: '#fff',
        height: '100%',
    }
}));

function TermsScreen() {
    const classes = useStyles();
    return (
        <Container maxWidth="md" component='div' className={classes.container3}>
            <img src={tds} alt='tds logo' style={{ width: '100%'}}/>
            <div className={classNames(classes.main, classes.mainRaised, classes.body)}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                <div className={classes.container2}>
                <Typography variant="h4" className={classes.termsTitle}>Terms and Conditions</Typography>
                  <p>Before accessing this site, please take time to read and review the terms and conditions carefully. By accessing this site, you represent and warrant to us that you have read and understood, and agree to, the terms and conditions set forth below. If you do not agree, we advise that you refrain from accessing this site.</p>
                  <ul className={classes.unorderedList}>
                    <li>Prices are subject to change without prior notice.</li>
                    <li>This site is not directed at children, and the services being offered are for individuals who are at least 18 years of age. Any person under 18 years whowishes to avail of the services must provide us in advance the relevant consent from his/her parent. Should anyone whom we know to be under 18 years old access the site and manifest his/her intention to avail of the services, we will use the information so disclosed by such minor only to respond and inform him/her that we must have his/her parental consent before he/she can avail of the services being offered in this site.</li>
                    <li>The contents of this site are provided for informational purposes only. It shall in no way be construed as substitute for professional medical advice and should not be relied upon in that regard. For diagnosis, treatment and prescription, consult your physician and other healthcare providers. You undertake to carefully read all product packaging and labels prior to use. Consult your physician and other healthcare providers before taking any goods being offered in this site.</li>
                    <li>For prescription medicine, we will not dispense the same without prescription from your physician.</li>
                    <li>The contents of this are provided “as is”. TDSTORE INC. (TDS), its affiliates, partners, agents and/or suppliers make no warranties, expressed or implied, and hereby disclaim and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
                    <li>Further, they do not warrant or make any representations concerning the accuracy, likely or desired results, reliability of the use of the goods. If at all, TDS, its affiliates, partners, agents and/or suppliers only liability is to replace the goods found to be defective or reimburse/credit you the equal amount of the purchase price of the goods. In no event shall TDS, its affiliates, partners, agents and/or suppliers be liable for any special, consequential, indirect, exemplary or incidental damages whatsoever resulting from the use of the goods or availing of the services being offered, whether such claim is based on contract, tort (including negligence), product liability or otherwise, even if advised of the possibility or likelihood of such damages.</li>
                    <li>For returns, please see FAQ for complete details. We reserve our right to revise and amend our return policy from time to time. You undertake to review the return policy each time you avail of the services being offered in this site. 
                    Customer warrants that the purchase of the products will not violate any laws. As such, Customer hereby holds TDS free and harmless from any and all loss or damage arising from or in connection with the breach of this warranty by the Customer.
                    Customer shall be responsible in engaging the local courier to whom the products will be delivered by TDS. TDS obligation ends upon delivery of the products to customer’s local courier and shall not be responsible to shipment to be made thereafter, including foreign destination.</li>
                  </ul>
                </div>
                </GridItem>
              </GridContainer>
            </div>
            <Footer />
        </Container>
    )
};

export default TermsScreen;
