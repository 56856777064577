// For my sanity, it seems that this is NOT JUST an action, but an action creator???
// Ok ok, then Reducers ONLY CHANGE STATE in response to these actions.
// So it's right that we call dispatch here 

const loginAction = ({
    email,
    password
}) => (dispatch, _, { getFirebase }) => {
    return getFirebase().login({ email, password }).then(fb => {
        dispatch({
            type: 'LOGIN',
            payload: {
                user: fb.user,
                user2: fb.user
            }
        });

        return fb;
    });
}

export default loginAction;
