import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
    GridContainer, GridItem,
    Card, CardHeader, CardBody, CardFooter,
    Button
} from '../components';

import styles from './styles';
const useStyles = makeStyles(styles);

function NotFound404() {
    const classes = useStyles();
    const history = useHistory();
    function goBack() {
      history.goBack();
    }
    return (
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h3>Where did that page go?</h3>
                  </CardHeader>
                  <CardBody>
                    Sorry, we couldn't find that page. Please check the URL or try to go back.

                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                  <Button color="primary" size="lg" block onClick={goBack}>
                    Go Back
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
    )
}

export default NotFound404;
