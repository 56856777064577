import React, { useState } from "react";
import { useDispatch } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { Alert } from '@material-ui/lab';
import { useHistory } from "react-router-dom";

// @material-ui/icons
import Email from "@material-ui/icons/Email"
import Lock from "@material-ui/icons/Lock"
// core components
import {
  Header, HeaderLinks,
  Footer,
  GridContainer, GridItem,
  Button,
  Card, CardBody, CardFooter, CardHeader,
  CustomInput
} from '../components';
import styles from "../assets/jss/material-kit-react/views/loginPage.js";
import image from "../assets/img/bg1.png";

import { APPLICATION_NAME } from '../constants';
import loginAction from '../actions/loginAction';

const useStyles = makeStyles(styles);

export default function LoginScreen(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function() {
      setCardAnimation("");
    }, 700);
    const classes = useStyles();
    const { ...rest } = props;

    const [email, setEmail ] = useState(''); 
    const [pass, setPass ] = useState('');
    const [signinError, setSigninError ] = useState();

    const onSubmit = function() {
      dispatch(loginAction({
        email,
        password: pass
      })).then(() => {
        console.log('LOG IN SUCCESS');
        setTimeout(() => {
          console.log('Redirecting to dashboard');
          try {
            history.push('/dashboard');
          } catch (err) {
            console.log('ERROR REDIRECTING', err);
          }
        }, 500);
      }).catch(err => {
        console.log('ERROR', err.message)
        setSigninError(err.message)
        setTimeout(() => {
          setSigninError('');
        }, 10000);
      });
    }

    return (<div>
      <Header
        absolute
        color="transparent"
        brand={APPLICATION_NAME}
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h3>Sign in</h3>
                  </CardHeader>
                  <CardBody>
                    <div style={{ textAlign: 'center' }}>
                      <Typography variant="body2">
                        Please enter your administrator credentials.
                      </Typography>
                    </div>
                    { signinError && <Alert severity="error">{signinError}</Alert>}
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        value: email,
                        onChange: (e) => { setEmail(e.target.value) },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        value: pass,
                        onKeyPress: (e) => {
                          if (e.key === 'Enter') {
                            onSubmit();
                          }
                        },
                        onChange: (e) => { setPass(e.target.value) },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock className={classes.inputIconsColor}></Lock>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                  </CardBody>
                </form>
                <CardFooter className={classes.cardFooter}>
                  <Button color="primary" size="lg" block onClick={onSubmit}>
                    Log In
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>);
}
