import React from 'react';
import classNames from "classnames";
import {
    Container,
    Typography
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import {
    Card, CardBody,
    Header, HeaderLinks,
    GridContainer, GridItem,
    Footer,
} from '../components';
import { APPLICATION_NAME } from '../constants';
import styles from "../assets/jss/material-kit-react/views/landingPage.js";
import banner from '../assets/img/tds-banner.png';

const useStyles = makeStyles(theme => ({ 
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3",
        // minWidth: '320px ',
      },
      mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        },
      },
      body: {
        marginTop: 36,
        padding: 16,
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            padding: 16,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 13,
        },
        },
        termsTitle: {
            textAlign: 'center',
            textTransform: 'capitalize',
            fontWeight: '700',
            marginTop: '15px',
        },
        supportDetails: {
            boxSizing: 'border-box',
            textAlign: 'center',
            fontSize: '1.3rem',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
                fontSize: '1.1rem'
            }
        },
        linkStyle: {
            display: 'inline-block',
            whiteSpace: 'nowrap',
            '&:hover': {
                color: 'rgba(31, 66, 135, 1)'
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
                fontSize: '1.1rem'
            }

        },
        cardBody: {
            borderRadius: '5px',
            '&:hover' : {
                boxShadow: '0 0 1px 2px rgba(0, 0, 0, .05)',
            }
        },
        container3: {
            backgroundColor: '#fff',
            height: '100vh',
            padding: '1.5rem',
            minWidth: '350px',
        }
}));

function SupportScreen() {
    const classes = useStyles();
    return (
        <Container maxWidth="md" className={classes.container3}>
          <div className={classNames(classes.main, classes.mainRaised, classes.body)}>
                <div className={classes.container2}>
                    <Typography variant="h4" className={classes.termsTitle}>Need help?</Typography>
                    <p className={classes.supportDetails}>Please email <a className={classes.linkStyle} href="mailto:business@thediabetesstore.com.ph">business@thediabetesstore.com.ph</a>&nbsp;or give us a call at <a className={classes.linkStyle} href="tel:+63 966 814 4730">+63 966 814 4730</a>.</p>
                </div>

                <Card>
                    <CardBody className={classes.cardBody}>
                    <img src={banner} style={{
                        width: '100%',
                        height: 'auto',
                    }} />
                    </CardBody>
                </Card>
          </div>
        </Container>
          
    );
}

export default SupportScreen;
