export default {
    firebase: {
        apiKey: "AIzaSyBGaMzxxiWzhs20b9Om2WD6Vi-I4njrOSU",
        authDomain: "ubereatsclone-jollibee.firebaseapp.com",
        databaseURL: "https://ubereatsclone-jollibee.firebaseio.com",
        projectId: "ubereatsclone-jollibee",
        storageBucket: "ubereatsclone-jollibee.appspot.com",
        messagingSenderId: "667922180882",
        appId: "1:667922180882:web:4bd4862f6f8e5f37d721cb",
        measurementId: "G-VK0CQ24GFF"
    },
    firestore: {
        userProfile: 'users',
        useFirestoreForProfile: true 
    },
    api: 'https://us-central1-ubereatsclone-jollibee.cloudfunctions.net',
};
