const styles = {
    loadingIndicator: {
        marginTop: -20,
        marginBottom: 20
    },
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    pageTitle: {
        marginBottom: 20,
    },
    dashbox: {
        height: 190,
    },
    orderTableButtons: {
        padding: 4
    },
    ordersOrdersPending: {
        backgroundColor: '#90EE90'
    },

    ordersOrderRejected: {
        backgroundColor: '#BB2222',
    },
    ordersDriverPending: {
        backgroundColor: 'khaki'
    },
    printLogo: {
        verticalAlign: 'middle',
        height: 50,
        width: 50,
    },
    printFooter: {
        position: 'absolute',
        bottom: 0,
        padding: 16,
    },
    modal: {
        padding: 24,
    },
    printHidden: {
        display: 'none'
    },
    nonPrintHidden: {
        display: 'none'
    },
    users: {

    },
    inventory: {

    },
    toast: {
        position: 'fixed',
        bottom: 60,
        width: 400,
    },
    pwdImgContainer: {
        // maxWidth: '50%',
        // border: '1px solid red',
    },
    chipStyle: {
        padding: '0 12px',
        display: 'flex',
        justifyContent: 'space-around',
        margin: '3px 0',
    },
    numberInput: {
        '&[type=number]': {
            '-moz-appearance': 'textfield',
          },
          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
    },
    gridTileRoot: {
        border: '1px solid rgba(72, 47, 247, .5)',
        '&:not(&:last-child)': {
            marginRight: '5px'
        }
    },
    gridTileImage: {
        width: '100%',
    }
};

export default styles;
