import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core';
import {
    Typography,
    Grid,
} from '@material-ui/core';

import Info from '@material-ui/icons/Info';
import Store from '@material-ui/icons/Store';
import DateRange from "@material-ui/icons/DateRange";
import Person from "@material-ui/icons/Person";
import PeopleAlt from "@material-ui/icons/PeopleAlt"

import { Bar, Line, defaults } from 'react-chartjs-2';
import {
    GridContainer,
    GridItem, 
    Card, CardHeader, CardBody, CardIcon, CardFooter,
} from '../components';
import LoggedInContainer from './LoggedInContainer';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { DELIVERY_FEE, CURRENCY_FORMATTER } from '../constants';
import styles from './styles';
import styles2 from '../assets/jss/material-kit-react/views/dashboardStyle.js';
const useStyles = makeStyles({
    ...styles2,
    ...styles,
});

defaults.global.defaultFontColor = '#F9F9F9';
const fillOpacity = `AA` // Hex suffix for colors

function DashboardScreen() {
    const classes = useStyles();
    const firestore = useFirestore();
    const pastDaysToCount = 7;
    const startDate = new Date(new Date() - (pastDaysToCount * 24 * 60 * 60 * 1000));
    const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD HH:mm');
    const endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD HH:mm');
    const orders = useSelector(state => state.firestore.ordered.restaurant_orders) || [];
    const users = useSelector(state => state.firestore.ordered.users) || [];
    const [ compareDate ] = useState(firestore.Timestamp.fromDate(
        startDate
    ));

    useFirestoreConnect([{
        collection: 'restaurant_orders',
        where: [['createdAt', '>', compareDate]]
    }, {
        collection: 'users',
    }]);

    const activeUsersFunction = (usersCollection) => {
        let usersArr = [];
        for (const user of usersCollection) {
            if (user.hasOwnProperty('lastOnlineTimestamp')) {
                // console.log(user, user.lastOnlineTimestamp, user.hasOwnProperty('lastOnlineTimestamp'))
                usersArr.push(user)
            }
        }
        return usersArr.filter((user) => {
            return moment(user.lastOnlineTimestamp.toDate()).format('YYYY-MM-DD hh:mm') > startOfMonth 
            && moment(user.lastOnlineTimestamp.toDate()).format('YYYY-MM-DD hh:mm') < endOfMonth
            && user.role !== 'superadmin' && user.role !== 'vendor' && user.role !== 'support'
            && user.role !== 'driver';
        });
    };
    


    const activeUsers = activeUsersFunction(users);
    // console.log('[active users]', activeUsers);
    // console.log('[start of month]', startOfMonth);
    // console.log('[end of month]', endOfMonth);
    
    // Fetch once (instead of using onSnapshot) to generate the chart data
    // NOTE: Order Accepted = Ready to Ship!
    const statuses = [
        'Order Placed', 'Preparing Order', 'Order Accepted', 'Order Rejected',
        'Order Shipped', // Temporary until driver is found.
        'Driver Pending', 'Driver Accepted', 'Driver Rejected',
        'In Transit', 'Order Completed', 'Order Received'
    ];
    const statusIdxMap = {};
    statuses.map((s, i) => {
        statusIdxMap[s] = i;
    })
    // Order is same as `statuses`
    const statusColors = [
        '#90EE90',
        '#9EC1CF',
        '#FDFD97',
        '#EE2347',
        '#FF6633',
        '#9EC1CF',
        '#90EE90',
        '#FF2347',
        '#888888',
        '#AAAAAA',
        '#FFFFFF',
    ]
    const pastDaysLabels = [];
    const statusCountsTotal = [];
    const countsPerDay = {};
    for (let i = 0; i < statuses.length; i++) {
        statusCountsTotal[i] = 0;
    }
    for (let i = 0; i < pastDaysToCount; i++) {
        const d = moment(startDate).add(i, 'days').format('MM/DD');
        countsPerDay[d] = 0;
        
    }
    let orderValue = 0;
    const customerIds = new Set();

    for (let i = 0; i < orders.length; i++) {
        const o = orders[i];
        const d = moment(o.createdAt.toDate()).format('MM/DD');
        if (o.status === 'Manually Shipped') {
            statusCountsTotal['Order Shipped']++;
        } else if (o.status === 'Manually Delivered') {
            statusCountsTotal['Order Complete']++;
        } else if (statusIdxMap[o.status] === undefined) {
            console.error("BAD STATUS", o.id, o.status)
        } else {
            statusCountsTotal[statusIdxMap[o.status]]++;
        }
        countsPerDay[d]++;

        orderValue = orderValue + o.products.reduce((sum, p) => sum + p.quantity * p.price, 0) +
            (o.deliveryFee != null ? o.deliveryFee : DELIVERY_FEE);
            // ^ Sometimes deliveryFee is 0, make sure to use non-strict equality and only apply the default IF it doesn't exist AT ALL.
        customerIds.add(o.author.id)
    }
    const arrayifiedCounts = [];
    for (let i = 0; i < pastDaysToCount; i++) {
        const d = moment(startDate).add(i, 'days').format('MM/DD');
        pastDaysLabels.push(d);
        arrayifiedCounts.push(countsPerDay[d]);
    }
    // Different chart data sets
    const dateData = {
        labels: pastDaysLabels,
        datasets: [{
            label: 'Count Per Day',
            data: arrayifiedCounts,
            fill: false,
            backgroundColor: '#FFFFFF',
            borderColor: '#FFFFFF' + fillOpacity
        }]
    };

    // General chart options
    const options = {
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            yAxes: [{
                ticks: {
                    // beginAtZero: true,
                },
            }],
        },
    };


    statuses[2] = "Ready To Ship";
    const statusData = {
        labels: statuses,
        datasets: [{
            label: 'Orders Per Status',
            data: statusCountsTotal,
            fill: false,
            backgroundColor: statusColors.map(c => c + fillOpacity),
            borderColor: statusColors
        }],
    }

   
    return (
        <LoggedInContainer orderBadge={statusCountsTotal[0]}>
            <GridItem xs={12} sm={12} md={10} className={classes.pageTitle}>
                <Typography variant="h4">Summary</Typography>
                <Typography variant="body2">A quick glance at the past 7 days.</Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
                <Card>
                    <CardHeader color="primary" className={classes.dashbox}>
                        <Line data={dateData} options={options} />
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>Order Count</h4>
                        <p className={classes.cardCategory}>Breakdown per day</p>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
                <Card>
                    <CardHeader color="primary" className={classes.dashbox}>
                        <Bar data={statusData} options={options} />
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>Statuses</h4>
                        <p className={classes.cardCategory}>Counts per order status</p>
                    </CardBody>
                </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={5}>
                <Card>
                    <CardHeader stats icon>
                        <CardIcon color="info">
                            <Info />
                        </CardIcon>
                        <p className={classes.cardCategory}># Orders</p>
                        <h3 className={classes.cardTitle}>{orders.length}</h3>
                    </CardHeader>
                    <CardFooter stats>
                        <div className={classes.stats}>
                            <DateRange />
                            Last 7 days
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={5}>
                <Card>
                    <CardHeader stats icon>
                        <CardIcon color="success">
                            <Store />
                        </CardIcon>
                        <p className={classes.cardCategory}>Total Order Value</p>
                        <h3 className={classes.cardTitle}>{CURRENCY_FORMATTER.format(orderValue)}</h3>
                    </CardHeader>
                    <CardFooter stats>
                        <div className={classes.stats}>
                            <DateRange />
                            Last 7 days
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>


            <GridItem xs={12} sm={12} md={5}>
                <Card>
                    <CardHeader stats icon>
                        <CardIcon color="warning">
                            <Person />
                        </CardIcon>
                        <p className={classes.cardCategory}>Unique Customers</p>
                        <h3 className={classes.cardTitle}>{customerIds.size}</h3>
                    </CardHeader>
                    <CardFooter stats>
                        <div className={classes.stats}>
                            <DateRange />
                            Last 7 days
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={5}>
                <Card>
                    <CardHeader stats icon>
                        <CardIcon color="primary">
                            <PeopleAlt />
                        </CardIcon>
                        <p className={classes.cardCategory}>Active Users</p>
                        <h3 className={classes.cardTitle}>{activeUsers.length}</h3>
                    </CardHeader>
                    <CardFooter stats>
                        <div className={classes.stats}>
                            <DateRange />
                            Last 30 Days
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            
            {/* <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader stats icon>
                        <CardIcon color="primary">
                            <Store />
                        </CardIcon>
                        <p className={classes.cardCategory}>Total Order Value</p>
                        <h3 className={classes.cardTitle}>₱{orderValue}</h3>
                    </CardHeader>
                    <CardFooter stats>
                        <div className={classes.stats}>
                            <DateRange />
                            Last 7 days
                        </div>
                    </CardFooter>
                </Card>
            </GridItem> */}
        </LoggedInContainer>
    );
}

export default DashboardScreen;
