import React, { useState, useEffect, createRef } from 'react';
import {
    useLocation
} from 'react-router-dom';
import {
  useFirebase
} from 'react-redux-firebase';

import {
  Button,
  Container,
  TextField,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import tds from '../../assets/img/tds-banner.png';
import './auth.css';

export default function AuthScreen(props) {
  const firebase = useFirebase(); // Should already be initialized
  const auth = firebase.auth();

  // TODO - Determine which application this user came from
  const logo = tds;

  const location = useLocation();

  const [ email, setEmail ] = useState('');
  const pass1 = createRef();
  const pass2 = createRef();
  const [ mode, setMode ] = useState('');
  const [ actionCode, setActionCode ] = useState('');
  useEffect(() => {
    // Do the handle detection only when the URL changes
    console.log('Location useEffect', location.search);
    const split = location.search.slice(1).split('&'); // remove `?` and split
    
    // Because the mode and actionCode state variables will be updated in the NEXT render, not this one!
    // So we create a temporary variable for them. 
    let tmpCode, tmpMode;
    split.map(s => {
      const keyValue = s.split('=');
      if (keyValue[0] === 'mode') {
        tmpMode = keyValue[1];
        setMode(keyValue[1]);
      } else if (keyValue[0] === 'oobCode') {
        setActionCode(keyValue[1]);
        tmpCode = keyValue[1];
      }
    });

    switch (tmpMode) {
      case 'resetPassword':
        tmpCode && handleResetPassword(tmpCode);
        break;
      case 'recoverEmail':
        tmpCode && handleRecoverEmail(tmpCode);
        break;
      case 'verifyEmail':
        tmpCode && handleVerifyEmail(tmpCode);
        break;
    }

    // Moved handler calls to below in render, with check for existence of both fields
  }, [ location.search ]);


  const [ alert, setAlert ] = useState({
    type: null,
    message: ''
  });
  const showAlert = (type, message) => {
    console.log('Showing alert', type, message);
    if (type === 'success' || type === 'error' || type === 'warning') {
      setAlert({ type, message });
    } else if (type === 'close') {
      setAlert({
        type: null,
        message: ''
      });
    } else {
      console.warn('Invalid alert woo', type);
    }
  }

  const errorHandler = (err) => {
    console.log('ERROR', mode, err);
    setMode('');
    showAlert('error', err.message);
  }
  const handleResetPassword = (code) => {
    console.log('Reset password');
  
    // Verify the password reset code is valid.
    return auth.verifyPasswordResetCode(code).then((email) => {
      console.log('Verified password reset', email);
      setEmail(email);
    }).catch(errorHandler);
  }
  
  const confirmPasswordReset = () => {
    console.log('RESET PASSWORD');

    // Save the new password.
    return auth.confirmPasswordReset(actionCode, pass1.current.value).then((resp) => {
      console.log('Password reset', resp);
      // Password reset has been confirmed and new password updated.

      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
      showAlert('success', 'Password was reset successfully.');
      setMode('resetPasswordDone');
    }).catch(errorHandler);
  }
  
  const handleRecoverEmail = (code) => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    var restoredEmail = null;
    // Confirm the action code is valid.
    auth.checkActionCode(code).then((info) => {
      // Get the restored email address.
      restoredEmail = info['data']['email'];
      console.log('Recover email code checked', info);
  
      // Revert to the old email.
      return auth.applyActionCode(actionCode);
    }).then(() => {
      console.log('DONE recovering email');
      // Account email reverted to restoredEmail
  
      // TODO: Display a confirmation message to the user.
      showAlert('success', 'Email recovery done.');
  
      // You might also want to give the user the option to reset their password
      // in case the account was compromised:
      return auth.sendPasswordResetEmail(restoredEmail).then(() => {
        setMode('recoverEmailDone');
        // Password reset confirmation sent. Ask user to check their email.
      })
    }).catch(errorHandler);
  }
  
  function handleVerifyEmail(code, continueUrl, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    auth.applyActionCode(code).then((resp) => {
      console.log('Email was verified!', resp);
      setMode('verifyEmailDone');
      // Email address has been verified.
  
      // TODO: Display a confirmation message to the user.
      // You could also provide the user with a link back to the app.
  
      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch(errorHandler);
  }
  
  let content = '';
  switch (mode) {
    case 'resetPassword':
      content = 'Please enter your new password.';
      break;
    case 'resetPasswordDone':
      content = 'Password reset successful. You may now return to the application.';
      break;
    case 'recoverEmail':
      content = '';
      break;
    case 'recoverEmailDone':
      content = 'You can also choose to reset your password by clicking the button below.'
      break;
    case 'verifyEmail':
      content = 'Verifying email address...';
      break;
    case 'verifyEmailDone':
      content = 'Email successfully verified.';    
      break;
    default:
      content = 'Something went wrong.'
      // Error: invalid mode.
  }

  console.log('Mode/Content:', mode, content);

  const textFieldStyle = {
    marginBottom: 12,
  };

  return (
    <Container maxWidth="sm" className="body">
      <img className="logo" src={logo} />
      <Typography variant="subtitle1" style={{textAlign: 'center'}}>{content}</Typography>

      {mode === 'resetPassword' ? <>
        <TextField
          id="email"
          label="Email"
          size="small"
          InputProps={{
            readOnly: true,
          }}
          fullWidth={true}
          style={textFieldStyle}
          value={email}
        />
        <TextField
          autoFocus={true}
          id="standard-password-input1"
          label="Password"
          type="password"
          autoComplete="new-password"
          inputRef={pass1}
          style={{
            ...textFieldStyle,
            marginRight: 16,
          }}
        />
        <TextField
          id="standard-password-input2"
          label="Retype password"
          type="password"
          autoComplete="new-password"
          inputRef={pass2}
          style={textFieldStyle}
        />
        <Button
          color="primary"
          variant="contained"
          fullWidth={true}
          onClick={() => {
            if (mode === 'resetPassword') {
              if (pass1.current.value === pass2.current.value) {
                confirmPasswordReset()
              } else {
                showAlert('error', 'Passwords do not match.');
              }
            }
          }}
        >Save</Button>
      </> : null}
      <Snackbar open={alert.type != null} autoHideDuration={5000} onClose={() => {showAlert('close')}}>
        <Alert onClose={() => { showAlert('close') }} severity={alert.type || ''}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Container>
  )
};
