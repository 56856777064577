import { VENDOR_ID } from '../constants';

const addProduct = ({
    firestore,
    productName,
    productCode,
    classification,
    description,
    minimumOrderQuantity,
    packaging,
    photo,
    quantityPerBox,
    unitOfMeasure,
    stock,
    companyA,
    companyB,
    companyC,
    retail,
    tags
}) => async (dispatch, _, { getFirebase }) => {
    console.log('PRE ADD PRODUCT')
    const tmp = firestore.collection('collectionName').doc();
    return firestore.set(`vendor_products/${tmp.id}`, {
        id: tmp.id,
        name: productName,
        vendorId: VENDOR_ID, // TODO - basd on auth'd user's vendorID property.
        classification,
        code: productCode,
        description: description || productName,
        stock,
        minimumOrderQuantity: minimumOrderQuantity || 1,
        packaging: packaging || 'BOX',
        photo,
        quantityPerBox: quantityPerBox || 1,
        unitOfMeasure: unitOfMeasure || '',
        companyA,
        companyB,
        companyC,
        retail,
        tags,
        createdAt: firestore.FieldValue.serverTimestamp()
    }).then(woot => {
        console.log('ADD PRODUCT DONE', typeof woot, woot);
        // TODO: Alternative, use the @@reactReduxWhateverPrefixItWas in the reducer /shrug
        dispatch({
            type: 'ADD_PRODUCT',
            payload: {
                productId: tmp.id,
                name: productName,
                vendorId: VENDOR_ID,
                classification,
                code: productCode,
                description: description || productName,
                minimumOrderQuantity: minimumOrderQuantity || 1,
                packaging: packaging || 'BOX',
                photo,
                quantityPerBox: quantityPerBox || 1,
                unitOfMeasure: unitOfMeasure || '',
            }
        });
    });
}

const updateProduct = ({
    productId,
    productName,
    stock,
    firestore
}) => async (dispatch, _, { getFirebase }) => {
    return firestore.update(`vendor_products/${productId}`, {
        productName,
        stock,
        updatedAt: firestore.FieldValue.serverTimestamp(),
    }).then(() => {
        console.log('UPDATE PRODUCT DONE');
        dispatch({
            type: 'UPDATE_PRODUCT',
            payload: {
                productId,
                stock,
            }
        });
    })
}
const deleteProduct = ({
    productId,
    productName,
    stock,
    firestore
}) => async (dispatch, _, { getFirebase }) => {
    return firestore.delete(`vendor_products/${productId}`)
}



export {
    addProduct,
    updateProduct,
    deleteProduct,
};
