import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    Header, HeaderLinks,
    Footer,
    GridContainer,
} from '../components';
import { APPLICATION_NAME } from '../constants';

const styles = {
    loadingIndicator: {
      marginTop: -20,
      marginBottom: 20
    },
    card: {
        background: "linear-gradient(180deg, white, transparent)",

        // Mostly from assets/jss/material-kit-react/components
        position: "relative",
        zIndex: "3",

        boxSizing: 'border-box',
        borderRadius: "6px",
        paddingTop: 20,
        marginTop: -30,
        marginLeft: 30,
        marginRight: 30,
    },
  };
const useStyles = makeStyles(styles);

/**
 * A wrapper for all views that are logged in.
 * Contains the header, footer, and the outermost GridContainer used for the layout.
 */
function LoggedInContainer(props) {
    const classes = useStyles();
    return (
        <>
            { props.loading &&
                <LinearProgress
                    styles={{ zIndex: 10, height: props.loading ? undefined : 0 }}
                    className={classes.loadingIndicator}
                    color="secondary"  
                />
            }
            <Header
                color="primary"
                brand={APPLICATION_NAME}
                rightLinks={<HeaderLinks orderBadge={props.orderBadge} />}
            />
            <GridContainer justify="center" className={classes.card}>
                {props.children}
            </GridContainer>
            <Footer />
        </>
    )
}

LoggedInContainer.propTypes = {
    loading: PropTypes.bool,
    orderBadge: PropTypes.any,
};

export default LoggedInContainer;
