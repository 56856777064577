import React from 'react';

import {
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions,
    Button,
} from '@material-ui/core';
import { dangerColor } from '../../assets/jss/material-kit-react';

const ProductDeleteModal = (props) => {
    const { product, close, deleteProduct } = props;


    return (
        <div>
            <DialogTitle style={{textAlign: 'center'}}> Are you sure you want to remove this product? </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">Product Code: <span style={{fontWeight: '700'}}>{` ${product.id}`}</span></Typography>
                <Typography variant="subtitle1">Product Name: <span style={{fontWeight: '700'}}>{` ${product.name}`}</span></Typography>
                <Typography variant="subtitle1">Stock Count:<span style={{fontWeight: '700'}}>{` ${product.stock}`}</span></Typography>
                <Typography variant="subtitle1" style={{textTransform: 'capitalize'}}>Packaging:<span style={{fontWeight: '700'}}>{` ${product.packaging}`}</span></Typography>
            </DialogContent> 
            <DialogActions>
                <Button onClick={close}>
                    Back
                </Button>
                <Button 
                 style={{ color: dangerColor}}
                 onClick={deleteProduct}
                 >
                    Delete
                </Button>
            </DialogActions>
        </div>
    )
}

export default ProductDeleteModal;
