import config from '../config';
import axios from 'axios';

const registerAction = (
    list,
    firestore,
) => async (dispatch, _, { getFirebase }) => {
    // const firebase = getFirebase();
    return axios({
        method: 'POST',
        url: config.api + '/users',
        data: list
    }).then(response => {
        console.log('RESPONSE FROM API', response.data);
        dispatch({
            type: 'BATCH_USER_UPLOAD',
            payload: response.data
        });
    }).catch(err => {
        console.log('API BATCH-CREATE FAILED', err.message);
        throw err;
    });
};

export default registerAction;
