import {
    SETTINGS_TABLE
} from '../constants';

const updateSetting = ({
    firestore,
    changes,
    uid,
    updatedAt,
}) => async (dispatch, _, { getFirebase }) => {
    const keys = Object.keys(changes);
    const batch = firestore.batch();
    const collectionRef = firestore.collection(SETTINGS_TABLE);
    for (let i = 0; i < keys.length; i++) {
        const settingName = keys[i];
        const settingValue = changes[keys[i]];
        const ref = collectionRef.doc(settingName);
        batch.update(ref, {
            value: settingValue,
            updatedAt: firestore.FieldValue.serverTimestamp(), 
            updatedBy: uid,
        })
    }
    return batch.commit().then(() => {
        dispatch({
            type: 'SETTINGS_UPDATE',
            payload: {
                changes,
                updatedAt
            } 
        })
    });
};


export {
    updateSetting,
};
