import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  Snackbar,
  Switch,
  Typography,
  TextField,
  InputAdornment
} from '@material-ui/core';
import {
  Alert,
} from '@material-ui/lab';
import { makeStyles } from "@material-ui/core/styles";
import LoggedInContainer from './LoggedInContainer';
import {
  GridContainer, GridItem,
  Button,
  Card, CardBody, CardFooter,
} from '../components';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import styles from "../assets/jss/material-kit-react/customCheckboxRadioSwitch.js";
import {
  DELIVERY_FEE,
  LOW_STOCK_THRESHOLD,
  SETTINGS_TABLE
} from '../constants';
import {
  updateSetting
} from '../actions/settingsActions';

const useStyles = makeStyles(styles);

export default function SettingsScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  useFirestoreConnect([ SETTINGS_TABLE ]);
  const settings = useSelector(state => state.firestore.data[SETTINGS_TABLE]) || [];
  const auth = useSelector(state => state.firebase.auth);
  const [openingTime, setOpeningTime] = useState('');
  const [closingTime, setClosingTime] = useState('');
  // console.log(closingTime, openingTime);

  const [ checkedA, setCheckedA ] = useState(false);
  const [ checkedB, setCheckedB ] = useState(false);
  const [ checkedC, setCheckedC ] = useState(false);
  const [ minPurchaseAmount, setMinPurchaseAmount ] = useState(0);
  const [ lowStockThreshold, setLowStockThreshold ] = useState(0);
  const [ changes, setChange ] = useState({})

  useEffect(() => {
    if (settings.delivery_fee) {
      setCheckedA( settings.delivery_fee.value === 0 ? true : false);
    } else {
      setCheckedA(false);
    }
  }, [settings.delivery_fee]);

  useEffect(() => {
    console.log(settings.first_delivery_free, 'first del')
    if (settings.first_delivery_free) {
      setCheckedB(settings.first_delivery_free.value === true ? true : false);
    } else {
      setCheckedB(false);
    }
  }, [settings.first_delivery_free]);

  useEffect(() => {
    // console.log(settings.first_delivery_free, 'first del')
    if (settings.min_purchase_free_delivery) {
      setCheckedC(settings.min_purchase_free_delivery.value === true ? true : false);
    } else {
      setCheckedC(false);
    }
  }, [settings.min_purchase_free_delivery]);

  useEffect(() => {
    // console.log(settings.first_delivery_free, 'first del')
    if (settings.min_purchase_amount) {
      setMinPurchaseAmount(settings.min_purchase_amount != null ? settings.min_purchase_amount.value : 0);
    }
  }, [settings.min_purchase_amount]);

  useEffect(() => {
    if (settings.low_stock_threshold) {
      setLowStockThreshold(settings.low_stock_threshold != null ? settings.low_stock_threshold.value : LOW_STOCK_THRESHOLD);
    }
  }, [settings.low_stock_threshold]);

  // useEffect(() => {
  //   if (settings.opening_hours && settings.closing_hours) {
  //     setOpeningTime(settings.opening_hours != null ? settings.opening_hours.value : "");
  //     setClosingTime(settings.closing_hours != null ? settings.closing_hours.value : "");
  //   }
  // }, [settings.opening_hours, settings.closing_hours]);

  const applySettings = () => {
    const updated = Date.now();
    const keys = Object.keys(changes);
    console.log('changes', changes);
    if (keys.length === 0) {
      showAlert('warning', 'No changes to be saved.');
      return;
    }

    dispatch(updateSetting({
      firestore,
      changes,
      uid: auth.uid,
      updatedAt: updated
    })).then(() => {
      showAlert('success', 'Settings updated.');
      setChange({});
    });
  }

  // Alerts
  const [ alert, setAlert ] = useState({
    type: null,
    message: ''
  });
  const showAlert = function (type, message) {
    if (type === 'close') {
      setAlert({ type: null, message: '' });
    } else {
      setAlert({ type, message });
    }
  }
  console.log('df', DELIVERY_FEE);

  
  return (
    <LoggedInContainer>
      <GridItem xs={12} md={10} className={classes.pageTitle}>
        <Typography variant="h4">Settings</Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardBody>
            {/* <GridContainer style={{marginBottom : 10}}>
              <GridItem xs={12} sm={12} md={2}>
                <Typography>Opening Time</Typography>
                    <TextField 
                    type="time"
                    value={openingTime}
                    onChange={(e) => {
                      setOpeningTime(e.target.value)
                      setChange({
                        ...changes,
                        opening_hours: e.target.value
                      })
                    }}
                    />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <Typography>Closing Time</Typography>
                    <TextField 
                    type="time"
                    value={closingTime}
                    onChange={(e) => { 
                      setClosingTime(e.target.value)
                      setChange({
                        ...changes,
                        closing_hours: e.target.value
                      })
                    }}
                    />
              </GridItem>
            </GridContainer> */}
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  type="number"
                  value={lowStockThreshold}
                  helperText="When to warn users that a product is low in stock."
                  label="Low Stock Threshold"
                  onChange={e => {
                    setLowStockThreshold(parseInt(e.target.value));
                    setChange({
                      ...changes,
                      low_stock_threshold: parseInt(e.target.value)
                    })
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={checkedA}
                      onChange={event => {
                        setChange({
                          ...changes,
                          delivery_fee: event.target.checked ? 0 : DELIVERY_FEE
                        })
                        setCheckedA(event.target.checked)
                      }}
                      value="checkedA"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        track: classes.switchBar
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={`Free delivery ${checkedA ? 'enabled' : 'disabled'} for all users`}
                  disabled={checkedB && checkedC}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={checkedB}
                      onChange={event => {
                        setChange({
                          ...changes,
                          first_delivery_free: event.target.checked
                        })
                        setCheckedB(event.target.checked)
                      }}
                      value="checkedB"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        track: classes.switchBar
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={`Free delivery ${checkedB ? 'enabled' : 'disabled' } for first time order`}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
              <FormControlLabel
                  control={
                    <Switch
                      checked={checkedC}
                      onChange={event => {
                        setChange({
                          ...changes,
                          min_purchase_free_delivery: event.target.checked
                        })
                        setCheckedC(event.target.checked)
                      }}
                      value="checkedB"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        track: classes.switchBar
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={`Free delivery ${checkedC ? 'enabled' : 'disabled' } for minimum purchase of ₱${minPurchaseAmount ? minPurchaseAmount: 0}`}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  type="number"
                  value={minPurchaseAmount}
                  helperText="Minimum purchase to avail free delivery."
                  label="Minimum Purchase Amount"
                  onChange={e => {
                    setMinPurchaseAmount(parseFloat(e.target.value));
                    setChange({
                      ...changes,
                      min_purchase_amount: parseFloat(e.target.value)
                    })
                  }}
                  disabled={!checkedC}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₱</InputAdornment>
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button onClick={applySettings} color="primary">Apply</Button>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem  xs={12} sm={12} md={8}>
        <Card>
          <CardBody>
            <GridContainer>
              These settings will apply to all users of the app. Please click "Apply" to make these changes effective immediately.
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <Snackbar className={classes.toast} open={alert != null && alert.type != null} autoHideDuration={5000} onClose={() => showAlert('close')}>
        <Alert onClose={() => { showAlert('close')}} severity={alert.type || 'success'}>
          {alert && alert.message}
        </Alert>
      </Snackbar>
    </LoggedInContainer>
  )
}