import React from "react";
import classNames from "classnames";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import {
  Header, Footer,
  GridContainer, GridItem,
  Button,
  HeaderLinks,
  Parallax
} from '../components';

import { APPLICATION_NAME } from '../constants';
import styles from "../assets/jss/material-kit-react/views/landingPage.js";
import LandingImage from "../assets/img/bg2.png";

// Sections for this page
import IntroSection from "./sections/IntroSection";

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const { ...rest } = props;

  const auth = useSelector(state => state.firebase.auth);
  if (auth.isLoaded && auth.uid) {
    console.log('Logged in, redirecting to Dashboard');
    return (
      <Redirect
        to={{
          pathname: "/dashboard",
        }}
      />
    );
  }

  return (
    <div>
      <Header
        color="transparent"
        brand={APPLICATION_NAME}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={LandingImage}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Warehouse Manager.</h1>
              <h4>
                Handle inventory and orders from one central website.
              </h4>
              <br />
              <Button
                color="danger"
                size="lg"
                onClick={() => {
                  history.push('/login');
                }}
              >
                <i className="fas fa-play" />
                Get Started
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <IntroSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}