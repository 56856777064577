// Note first argument `firestore`, while #839 not fixed.
// See https://github.com/prescottprue/react-redux-firebase/issues/839

const approveOrder = ({
    firestore,
    orderId,
    updatedAt,
}) => async (dispatch, _,  { getFirebase }) => {
    console.log('PRE-APPROVE', firestore, orderId, updatedAt);
    return firestore.update(`restaurant_orders/${orderId}`, {
        status: "Preparing Order",
        updatedAt: firestore.FieldValue.serverTimestamp()
    }).then(fs2 =>{
        console.log('APPROVE COMPLETE', typeof fs2);
        dispatch({
            type: 'PREPARE_ORDER',
            payload: {
                orderId,
                status: 'Order Accepted',
                updatedAt,
            }
        });
    });
}

const shipOrder = ({
    firestore,
    orderId,
    products,
    updatedAt,
}) => async (dispatch, _, { getFirebase }) => {
    console.log('PRE-APPROVE', firestore, orderId, updatedAt);
    return firestore.update(`restaurant_orders/${orderId}`, {
        status: "Order Accepted",
        updatedAt: firestore.FieldValue.serverTimestamp(),
    }).then(fs2 =>{
        console.log('SHIP COMPLETE', typeof fs2);
        // Update product stocks

        const all = products.map(p => {
            console.log('Updating product quantity', p.id, p.quantity);
            return firestore.update(`vendor_products/${p.id}`, {
                stock: firestore.FieldValue.increment(-p.quantity),
                updatedAt: firestore.FieldValue.serverTimestamp(),
            });
        })
        return Promise.all(all);
    }).then(stockUpdates => {
        console.log('Stock update done', stockUpdates);
        dispatch({
            type: 'APPROVE_ORDER',
            payload: {
                orderId,
                status: 'Order Accepted',
                updatedAt
            }
        });
    });
}

const supportOrderUpdate = ({
    firestore,
    orderId,
    status,
    updatedAt
}) => async (dispatch, _, { getFirebase }) => {
    return firestore.update(`restaurant_orders/${orderId}`, {
        status,
        updatedAt: firestore.FieldValue.serverTimestamp()
    }).then(() => {
        console.log('ORDER UPDATED', orderId, status, updatedAt);
        dispatch({
            type: 'SUPPORT_UPDATE',
            payload: {
                orderId,
                status,
                updatedAt,
            }
        })
    });
};

const manualShip = ({
    firestore,
    orderId,
    comment,
    updatedAt,
}) => async (dispatch, _, { getFirebase }) => {
    console.log('MANUAL SHIP', updatedAt);
    return firestore.update(`restaurant_orders/${orderId}`, {
        status: 'Manually Shipped',
        comment,
        updatedAt: firestore.FieldValue.serverTimestamp(),
    }).then(() => {
        console.log('Manual ship complete');
        dispatch({
            type: 'MANUAL_SHIP_ORDER',
            payload: {
                orderId,
                status: 'Manually Shipped',
                comment,
                updatedAt
            }
        });
    });
}

const manualDeliver = ({
    firestore,
    orderId,
    updatedAt,
}) => async (dispatch, _, { getFirebase }) => {
    console.log('MANUAL DELIVER', orderId, updatedAt);
    return firestore.update(`restaurant_orders/${orderId}`, {
        status: 'Manually Delivered',
        updatedAt: firestore.FieldValue.serverTimestamp(),
    }).then(() => {
        console.log('Manual ship complete');
        dispatch({
            type: 'MANUAL_DELIVERY_ORDER',
            payload: {
                orderId,
                status: 'Manually Delivered',
                updatedAt
            }
        });
    });
}

const rejectOrder = ({
    firestore,
    orderId,
    reason,
    updatedAt
}) => async (dispatch, _, { getFirebase }) => {
    console.log('PRE-REJECT', firestore, orderId, updatedAt);
    return firestore.update(`restaurant_orders/${orderId}`, {
        status: "Order Rejected",
        reason,
        updatedAt: firestore.FieldValue.serverTimestamp(),
    }).then(fb => {
        console.log('REJECT COMPLETE', typeof fb);
        dispatch({
            type: 'REJECT_ORDER',
            payload: {
                orderId,
                status: 'Order Rejected',
                reason,
                updatedAt
            }
        });
    });
}

const setOrderDriver = (
    firestore,
    orderId,
    driver
) => async (dispatch, _, { getFirebase }) => {
    console.log('SETTING DRIVER', orderId);
    return firestore.update(`restaurant_orders/${orderId}`, {
        driver, 
        status: 'In Transit',
        updatedAt: firestore.FieldValue.serverTimestamp()
    }).then(fs2 =>{
        console.log('APPPROVE COMPLETE', Object.keys(fs2));
        dispatch({
            type: 'APPROVE_ORDER',
            payload: {
                orderId,
                driver,
                timestamp: Date.now(),
            }
        });
    });
}

const reshipOrder = ({
    firestore,
    orderId,
    updatedAt,
}) => async (dispatch, _, { getFirebase }) => {
    return firestore.update(`restaurant_orders/${orderId}`, {
        status: "Preparing Order",
    }).then(() =>{
        return firestore.update(`restaurant_orders/${orderId}`, {
            status: "Order Accepted",
            updatedAt: firestore.FieldValue.serverTimestamp(),
        });
    }).then(() => {
        console.log('Reship done', orderId);
        dispatch({
            type: 'APPROVE_ORDER',
            payload: {
                orderId,
                status: 'Order Accepted',
                updatedAt,
            }
        });
    });
};

export {
    approveOrder,
    rejectOrder,
    reshipOrder,
    shipOrder,
    manualShip,
    manualDeliver,
    setOrderDriver,
    supportOrderUpdate,
}
