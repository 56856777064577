const updateUser = ({
    firestore,
    userId,
    role,
    updatedAt,
}) => async (dispatch, _,  { getFirebase }) => {
    console.log('PRE-UPDATE', firestore, userId, updatedAt);
    return firestore.update(`users/${userId}`, {
        role,
        updatedAt: firestore.FieldValue.serverTimestamp()
    }).then(() =>{
        console.log('Update COMPLETE', updatedAt);
        dispatch({
            type: 'UPDATE_USER',
            payload: {
                userId,
                role,
                updatedAt,
            }
        });
    });
}

export {
    updateUser
}