import { VENDOR_ID } from '../constants';

const batchUploadProducts = (
    list,
    firestore
) => async (dispatch, _, { getFirebase }) => {
    const all = Promise.all(list.map((item, itemIdx) => {
        item.id = item.id || item.code;
        const params = {
            id:                   item.id,
            name:                 item.name,
            vendorID:             VENDOR_ID,
            categoryID:           item.categoryID,
            classification:       item.classification,
            code:                 item.code,
            description:          item.description || item.name,
            minimumOrderQuantity: parseInt(item.minimumOrderQuantity) || 1,
            packaging:            item.packaging || 'BOX',
            photo:                item.photo,
            stock:                parseInt(item.stock),
            quantityPerBox:       parseInt(item.quantityPerBox) || 1,
            unitOfMeasure:        item.unitOfMeasure || '',
            companyA:             parseFloat(item.companyA),
            companyB:             parseFloat(item.companyB),
            companyC:             parseFloat(item.companyC),
            retail:               parseFloat(item.retail),
            tags:                 item.tags || '',
            createdAt:            firestore.FieldValue.serverTimestamp(), // TODO - Blank?
            updatedAt:            firestore.FieldValue.serverTimestamp(),
            photos:               item.photos || '',
        };
        // TODO - We update by default but this would cause two-writes for new products
        // But I don't want to risk overwriting something that was not originally there (manually edited)
        return firestore.update(`/vendor_products/${item.id}`, params).catch(err => {
            if (err.code === 'not-found') {
                return firestore.set(`vendor_products/${item.id}`, params);
            }
            throw err;
        });
    })).then(() => {
        console.log('BATCH PRODUCT DONE', list.length);
        // TODO: Alternative, use the @@reactReduxWhateverPrefixItWas in the reducer /shrug
        dispatch({
            type: 'BATCH_PRODUCT',
            payload: list
        });
    });

    return all;
};

export default batchUploadProducts;
