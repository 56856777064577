import React, { useState, useEffect } from "react";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ProductDeleteModal from './modals/ProductDeleteModal';
import ProductPhotosModal from './modals/ProductPhotosModal';
// @material-ui/core
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  InputLabel,
  Input,
  Switch,
  Select, MenuItem,
  TableContainer, Table, TableHead, TableBody,
  TablePagination,
  TableRow, TableCell,
  Typography,
  Snackbar,
  Tooltip,
  Dialog,
  IconButton,
} from '@material-ui/core';
import {
  Alert,
  TreeView, TreeItem,
} from '@material-ui/lab';
import { CSVLink } from "react-csv";
import InlineEdit from 'inline-edit-react';

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Delete from '@material-ui/icons/Delete';
import Visibility from '@material-ui/icons/Visibility';
// core components
import {
    Button,
    Card,
    GridItem,
    EmptyView,
    ProductsUploadStepper,
 } from "../components";
import LoggedInContainer from './LoggedInContainer';

import { useDispatch, useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { updateProduct, deleteProduct } from '../actions/productActions';

import {
  CURRENCY_FORMATTER,
  LOW_STOCK_THRESHOLD,
  VENDOR_ID,
  SETTINGS_TABLE
} from '../constants';

const CustomTreeItem = withStyles({
  root: {},
  selected: {},
  content: {},
  label: {
    fontSize: '0.75rem',
  }
})(TreeItem);

const styles = {

};
const useStyles = makeStyles(styles);

export default function InventoryScreen(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(true);
  const [ modalBody, setModalBody ] = useState(null);
  const [ tablePage, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(25);
  const [ displayProducts, setProducts ] = useState([]);
  const [ alert, setAlert ] = useState({ type: null, message: '' });
  const [ filteredCount, setFilteredCount ] = useState(0);
  const [ csvData, setCsvData ] = useState([]);
  const [ filters, setFilters ] = useState({
    vendorID: VENDOR_ID, // Should depend on auth'd admin user, but fixed for now.

    // All possible filters
    category: 'any',
    vendor: 'any',
    classification: 'any',
    lowStock: false,
    name: ''
  });

  useFirestoreConnect([
    {
      collection: 'vendor_products',
      where: [[ 'vendorID', '==', VENDOR_ID ]],
      // orderBy: ['createdAt', 'desc'], // Fails (empty) if no `createdAt` field
    },
    { 
      collection: 'vendor_categories',
      where: [[ 'vendorID', '==', VENDOR_ID]],
    },
    {
      collection: SETTINGS_TABLE
    }
  ]);

  const products = useSelector((state) => state.firestore.ordered.vendor_products);
  const settings = useSelector(state => state.firestore.data[SETTINGS_TABLE] || []);
  const categoriesMap = useSelector((state) => state.firestore.data.vendor_categories || []);
  const companies = [{
    id: "companyA",
    displayName: "Company A"
  }, {
    id: "companyB",
    displayName: "Company B"
  }, {
    id: "companyC",
    displayName: "Company C"
  }, {
    id: 'retail',
    displayName: 'Retail'
  }];
  const vendors = [{
    id: 'W7zO9ojOPCHb9dsz4EO6',
    displayName: 'The Diabetes Store',
  }, {
    id: '3NTjZxbC3bVl9M7sEARJ',
    displayName: 'Active One RX'
  }]
  const categories = Object.keys(categoriesMap);

  useEffect(() => {
    setProducts(filterAllProductsByFilterAndPage(products, filters, tablePage, pageSize));
  }, [products, tablePage, pageSize, filters]);

  useEffect(() => {
    const formatProducts = (productsArray) => {
      return productsArray.map((prod) => {
        let newObj = {};
        newObj["id"] = prod.id;
        newObj["classification"] = prod.classification;
        newObj["categoryID"] = prod.categoryID;
        newObj["photo"] = prod.photo;
        newObj["code"] = prod.code;
        newObj["stock"] = prod.stock;
        newObj["name"] = prod.name;
        newObj["description"] = prod.description;
        newObj["quantityPerBox"] = prod.quantityPerBox;
        newObj["packaging"] = prod.packaging;
        newObj["minimumOrderQuantity"] = prod.minimumOrderQuantity;
        newObj["unitOfMeasure"] = prod.unitOfMeasure;
        newObj["companyA"] = prod.companyA;
        newObj["companyB"] = prod.companyB;
        newObj["companyC"] = prod.companyC;
        newObj["retail"] = prod.retail;
        newObj["tags"] = prod.tags;
        return newObj;
      })
    };
    if (products) {
      const newProducts = formatProducts(products);
      setCsvData(newProducts);
    }
  }, [products]);



  // HANDLERS
  function filterAllProductsByFilterAndPage(all, filters, page, size) {
    if (!all || all.length === 0) {
      return [];
    }
    const filterKeys = Object.keys(filters);
    const x = all.filter(p => {
      return filterKeys.reduce((running, key) => {
        // if (key === 'vendor' && filters[key] !== 'any') {
        //   return running && p.vendorID === filters[key];
        // } else
        if (key === 'category') {
          if (filters[key] !== 'any') {
            return running && p.categoryID === filters[key];
          }
          return running;
        } else if (key === 'classification') {
          if (filters[key] !== 'any') {
            return p.classification === filters[key];
          }
          return running;
        } else if (key === 'lowStock' && filters[key] && settings) {
          return running && p.stock < settings.low_stock_threshold.value;
        } else if (key === 'name' && filters.name.length > 0) {
          return running && (
            p.name       .toLowerCase().indexOf(filters[key].toLowerCase()) > -1 ||
            p.description.toLowerCase().indexOf(filters[key].toLowerCase()) > -1 ||
            p.code       .toLowerCase().indexOf(filters[key].toLowerCase()) > -1
          )
        }
        return running;
      }, true);
    });
    setLoading(false);
    setFilteredCount(x.length);
    return x.slice((page - 1) * size, page * size);
  }
  const handleChange = (event) => {
    const name = event.target.name;
    if (name === 'vendor') {
      console.log('VENDOR', event.target.value);
    }
    if (name === 'lowStock') {
      setFilters({
        ...filters,
        lowStock: event.target.checked,
      });
      return;
    }
    setFilters({
      ...filters,
      [name]: event.target.value,
    });
    setPage(1);
  }
  const showAlert = ({ type, message}) => {
    if (type === 'success') {
      setAlert({ type, message })
    } else if (type === 'close') {
      setAlert({
        type: null,
        message: '',
      });
    } else {
      console.warn('Invalid alert woo', type);
    }
  }

  const deleteProductHandler = (i) => {
    const selectedProduct = displayProducts[i];
    dispatch(deleteProduct({productId: selectedProduct.id, productName: selectedProduct.name, stock: selectedProduct.stock, firestore})).then(() => {
      showAlert({type: 'success', message: 'Product Successfully deleted'});
      setModalBody(null);
    });
  };

  const openModal = (which, i) => {
    const selectedProduct = displayProducts[i];
    if (which === 'close') {
      setModalBody(null)
    } else if (which === 'upload') {
      setModalBody(
        <ProductsUploadStepper onClose={() => openModal('close')}/>
      )
    } else if (which === 'delete') {
        setModalBody(
          <ProductDeleteModal 
           product={selectedProduct}
           close={() => openModal('close')} 
           deleteProduct={() => deleteProductHandler(i)}
          />
        );
    } else if(which === 'view') {
        setModalBody(
          <ProductPhotosModal 
          product={selectedProduct}
          close={() => openModal('close')}
          />
      )
    } else {
      setModalBody(null)
    }
  }
  const onChangePage = (_, newPage) => {
    setPage(newPage + 1);
  }
  const onChangeRowsPerPage = (e) => {
    setPageSize(e.target.value);
    // Current "top" index of old page / new page + 1
    const newPage = Math.floor(((tablePage - 1) * pageSize)  / e.target.value) + 1;
    setPage(newPage)
  }


  // FINAL RENDER
  return (
    <div>
      <LoggedInContainer loading={loading}>
        <GridItem xs={12} md={8}>
          <Typography variant="h4">Manage Inventory</Typography>
        </GridItem>
        <GridItem xs={12} md={2}>
          <Tooltip title="Add products and update inventory stocks." arrow>
            <Button style={{ marginBottom: 8}} color="primary" onClick={() => openModal('upload')}>
              Upload Products  
            </Button>
          </Tooltip>
          <Tooltip title="Export Inventory List" arrow>
            <CSVLink
             data={csvData}
             filename="inventory-list.csv"
             style={{textDecoration: 'none'}}
            >
            <Button style={{ marginBottom: 8}} color="primary">
              Export Inventory  
            </Button>
          </CSVLink>
          </Tooltip>
        </GridItem>
        <GridItem xs={12} md={10} style={{ paddingTop: 8 }}>
          <FormControl className={classes.formControl} style={{marginRight: 16}}>
              <InputLabel>Category</InputLabel>
              <Select name="category" 
                value={filters.category}
                onChange={handleChange}
              >
                <MenuItem value={"any"}>Any</MenuItem>
                { categories.map(c => (
                  <MenuItem value={c} key={c}>{c}</MenuItem>
                )) }
              </Select>
              <FormHelperText>Filter by product category</FormHelperText>
            </FormControl>
            {/* <FormControl className={classes.formControl} style={{marginRight: 16}}>
              <InputLabel>Vendor</InputLabel>
              <Select name="vendor"
                value={filters.vendor}
                onChange={handleChange}
              >
                <MenuItem value={"any"}>Any</MenuItem>
                { vendors.map(v => (
                  <MenuItem value={v.id} key={v.id}>{v.displayName}</MenuItem>
                )) }
              </Select>
              <FormHelperText>Filter products by vendor</FormHelperText>
            </FormControl> */}
            <FormControl className={classes.formControl} style={{marginRight: 16}}>
              <InputLabel>Classification</InputLabel>
              <Select name="classification" 
                value={filters.classification}
                onChange={handleChange}
              >
                <MenuItem value={"any"}>Any</MenuItem>
                <MenuItem value={"RX"}>RX</MenuItem>
                <MenuItem value={"OTC"}>OTC</MenuItem>
              </Select>
              <FormHelperText>Filter RX-only or OTC-only</FormHelperText>
            </FormControl>
            <FormControl style={{marginRight: 16}}>
              <InputLabel>Name or Code</InputLabel>
              <Input name="name" value={filters.name} onChange={handleChange} />
              <FormHelperText>Filter products by name, description, or code.</FormHelperText>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={filters.lowStock}
                  onChange={handleChange}
                  name="lowStock"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    iconChecked: classes.switchIconChecked,
                    track: classes.switchBar
                  }}
                />
              }
              classes={{
                label: classes.label
              }}
              label="Filter low-stock only."
            />
        </GridItem>
        <GridItem xs={12} md={10}>
          <Card>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>OTC/RX</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Stock</TableCell>
                    <TableCell>Min. Order Qty.</TableCell>
                    <TableCell>Unit Of Measure</TableCell>
                    <TableCell>Packaging</TableCell>
                    {companies && companies.map((co, i) => (
                      <TableCell key={i}>{co.displayName}</TableCell>
                    ))}
                    <TableCell align="center"> Actions </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayProducts && displayProducts.map((p, i) => {
                    return (
                      <TableRow key={p.id}>
                        <TableCell>{p.classification}</TableCell>
                        <TableCell>{p.code}</TableCell>
                        <TableCell style={{padding: 0}}>
                          {/* <TreeView
                            disableSelection={true}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                          >
                            <TreeItem label={p.name} nodeId={p.id + "1"}>
                              <CustomTreeItem
                                nodeId={p.id + "2"}
                                label={`Lot: 12345  – Expiry: 12/31/2021`} />
                              <CustomTreeItem
                                nodeId={p.id + "3"}
                                label="Lot: 67890 – Expiry: 12/31/2021" />
                            </TreeItem>
                          </TreeView> */}
                          {p.name}
                        </TableCell>
                        <TableCell className={classes.textCenter}>
                          {p.stock || 0}
                          {/* <InlineEdit
                            initTitle={p.stock || 0}
                            onEdit={(newQuantity) => {
                              console.log("EDIT", newQuantity);
                              dispatch(updateProduct({
                                firestore,
                                stock: newQuantity,
                                productId: p.id,
                              })).then(() => {
                                console.log('QUANTITY UPDATED');
                                showAlert({ type: 'success', message: 'Edited product quantity.' });
                              }).catch(err => {
                                console.log('ERROR UPDATING PRODUCT QUANTITY', err);
                                showAlert({ type: 'error', message: err.message });
                              });
                            }}
                            onFail={(err) => {
                              console.log('ON FAIL', p.id, err);
                            }}
                          /> */}
                        </TableCell>
                        <TableCell>{p.minimumOrderQuantity}</TableCell>
                        <TableCell>{p.unitOfMeasure}</TableCell>
                        <TableCell>{p.quantityPerBox} / {p.packaging}</TableCell>
                        {companies && companies.map((co, j) => {
                          const price = p[co.id] || p.retail || 0;
                          return (
                            <TableCell key={j} style={{ textAlign: 'right', padding: 0 }}>
                              {CURRENCY_FORMATTER.format(price)}
                            </TableCell>
                          )
                        })}
                        <TableCell style={{display: 'flex', flexDirection: 'row', minHeight: '50px', alignItems: 'center'}}>
                        <Tooltip title="View Product Shots">
                            <IconButton onClick={() => openModal('view', i)}>
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Product">
                            <IconButton onClick={() => openModal('delete', i)}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {displayProducts.length === 0 && <EmptyView colSpan={8 + companies.length} />}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={tablePage - 1}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredCount}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Card>
        </GridItem>
      </LoggedInContainer>

      <Snackbar open={alert != null && alert.type} autoHideDuration={5000} onClose={() => {showAlert({type: 'close'})}}>
        <Alert onClose={() => { showAlert({type: 'close'}) }} severity={alert.type || ''}>
          {alert.message}
        </Alert>
      </Snackbar>

      <Dialog open={modalBody !== null}
        styles={classes.modal}
        onClose={() => openModal('close')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {modalBody}
      </Dialog>
    </div>
  )
}
