import React from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import CircularProgress from '@material-ui/core/CircularProgress';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export default function PrivateRoute({ children, comp, ...rest }) {
    const auth = useSelector(state => state.firebase.auth)
    const Comp = comp;
    return (
      <Route
        {...rest}
        render={
          ({ location }) => {
            if (!isLoaded(auth)) {
              return <CircularProgress style={{ marginLeft: 'calc(50% - 40px)', marginTop: 30 }}  />;
            }

            return isLoaded(auth) && !isEmpty(auth) ? (
              <Comp />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
            )
          }
        }
      />
    );
}
  