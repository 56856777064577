import { 
    AuthScreen,
    LandingScreen,
    LoginScreen,
    DashboardScreen,
    OrdersScreen,
    InventoryScreen,
    SettingsScreen,
    SupportScreen,
    TermsScreen,
    UsersUploadScreen,
    UsersVerifyScreen,
    OrdersRefundScreen,
} from "./views";

const routes = [
    { path: "/auth",          component: AuthScreen },
    { path: "/login",         component: LoginScreen },
    { path: "/terms",         component: TermsScreen },
    { path: "/support",       component: SupportScreen },
    { path: "/privacy",       component: TermsScreen },
    { path: "/dashboard",     component: DashboardScreen,    private: true },
    { path: "/orders/refund", component: OrdersRefundScreen, private: true },
    { path: "/orders",        component: OrdersScreen,       private: true },
    { path: "/inventory",     component: InventoryScreen,    private: true },
    { path: "/users/upload",  component: UsersUploadScreen,  private: true },
    { path: "/users/verify",  component: UsersVerifyScreen,  private: true },
    { path: "/settings",      component: SettingsScreen,     private: true },
    { path: "/",
      strict: true,
      render: () => {
        if (true) {
            return (
                <LandingScreen />
            );
        } else {
            return (
                <DashboardScreen />
            )
        }
      }
    }
];

export default routes;
