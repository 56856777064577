import React from 'react';
import classNames from "classnames";
import {
    Typography
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import {
    Header, HeaderLinks,
    GridContainer, GridItem,
    Footer,
} from '../components';
import { APPLICATION_NAME } from '../constants';
import styles from "../assets/jss/material-kit-react/views/landingPage.js";

const styles2 = {
    body: {
        marginTop: 36,
        padding: 16,
        marginBottom: 16,
    },
    container2: {
        paddingTop: 24,
    }
}
const useStyles = makeStyles({ ...styles, ...styles2 });

function PrivacyScreen() {
    const classes = useStyles();

    return (
        <div>
            <Header
                color="primary"
                brand={APPLICATION_NAME}
                rightLinks={<HeaderLinks />}
            />
            <div className={classNames(classes.main, classes.mainRaised, classes.body)}>

              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                <div className={classes.container2}>
                <Typography variant="h4">Privacy Policy</Typography>

                </div>
                </GridItem>
              </GridContainer>
            </div>

            <Footer />
        </div>
    )
};

export default PrivacyScreen;
